import { defineStore } from "pinia";
import { useSponsorStore } from "./sponsorshipsStore";
import api from "@/api";

export const useUnitStore = defineStore("units", {
  state: () => ({
    allUnits: [],
    allUnitNumbers: [],
    unit: null,
    currentUnitUsers: [],
    permissionGroupID: null,
    pendingUnitRequests: 0,
    pendingUserRequests: 0,
    lastFetchUnits: null,
    lastFetchUnitUsers: null,
    checklist: [],
  }),
  actions: {
    async getUnits() {
      const units = await api.getAllUnits();
      this.allUnits = units.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      this.allUnitNumbers = units.map((unit) => unit.unit);

      this.pendingUnitRequests = units.filter((req) => !req.approved).length;
      this.lastFetchUnits = new Date();
    },
    async getUnitUserData() {
      try {
        const sponsor = useSponsorStore();
        const perm = await api.getUserPermissions();
        let currentUnitUsers = await api.getUnitUsers(sponsor.unitId);
        let count = 0;
        currentUnitUsers = currentUnitUsers.map((user) => {
          if (user.status === "pending") {
            count++;
          }
          let userPermissions = perm.filter((el) => el.email === user.email);
          // TODO: REFACTOR THIS IN THE FUTURE
          if (userPermissions.length > 1) {
            let isSiteAdmin = { state: false, index: null },
              isAdmin = { state: false, index: null };
            userPermissions.map((perm, i) => {
              if (perm.groupName === "siteAdmin") {
                isSiteAdmin.state = true;
                isSiteAdmin.index = i;
              } else if (perm.groupname === "admin") {
                isAdmin.state = true;
                isAdmin.index = i;
              }
            });
            if (isSiteAdmin.state) {
              userPermissions = userPermissions[isSiteAdmin.index];
            } else if (isAdmin.state) {
              userPermissions = userPermissions[isAdmin.index];
            }
          } else {
            userPermissions = userPermissions[0];
          }

          return { ...userPermissions, ...user };
        });
        this.pendingUserRequests = count;
        this.currentUnitUsers = currentUnitUsers;
        this.lastFetchUnitUsers = new Date();
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async getChecklist() {
      const sponsor = useSponsorStore();
      const data = await api.getUnitChecklist(sponsor.unitId);
      this.checklist = data;
    },
    async updateChecklist(checklistData) {
      const sponsor = useSponsorStore();
      await api.updateUnitChecklist(sponsor.unitId, checklistData);
      const data = await api.getUnitChecklist(sponsor.unitId);
      this.checklist = data;
    },
  },
});
