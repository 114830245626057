import { defineStore } from "pinia";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    displayName: null,
    username: null,
    roles: [],
    token: undefined,
  }),
  getters: {
    isSiteAdmin() {
      return this.roles.includes("spawner-site-admin");
    },
    isAdmin() {
      return this.roles.includes("spawner-admin");
    },
    isSponsor() {
      return this.roles.includes("spawner-sponsor");
    },
  },
  actions: {
    setAuth(displayName, username, roles, token) {
      this.displayName = displayName;
      this.username = username;
      this.roles = roles;
      this.token = token;
    },
  },
});
