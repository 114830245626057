<template>
  <div style="
                                                        display: flex;
                                                        flex-direction: column;
                                                        justify-content: space-between;
                                                        height: 100%;
                                                      ">
    <div>
      <span>
        <h2 style="margin-bottom: 0; padding-bottom: 0; font-size: 2rem">
          Inprocessing Checklist
        </h2>
        <p style="margin-top: 0; padding-top: 0">for {{ data.memberName }}</p>
      </span>
      <n-divider />
      <div v-if="loading">
        <n-spin />
      </div>
      <n-scrollbar v-else style="height: 75vh">
        <div v-for="item in checklistItems" :key="item">
          <div style="
                                                                display: flex;
                                                                align-items: center;
                                                                justify-content: space-between;
                                                              ">
            <div style="display: flex; flex-direction: column">
              <h4 style="margin: 0">{{ item.title }}</h4>
              <n-collapse>
                <n-collapse-item title="Description">
                  <p>{{ item.description }}</p>
                </n-collapse-item>
              </n-collapse>
            </div>
            <n-checkbox style="margin-right: 20px" size="large" v-model:checked="item.completeStatus" />
          </div>
          <n-divider />
        </div>
      </n-scrollbar>
    </div>
    <div>
      <n-button @click="onSave" :focusable="false">Save Checklist</n-button>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import api from "@/api";
import {
  NDivider,
  NCheckbox,
  NIcon,
  NTooltip,
  NSpin,
  NButton,
  NCollapse,
  NScrollbar,
  NCollapseItem,
} from "naive-ui";
import { InfoOutlined } from "@vicons/material";
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  data: {
    type: Object,
    required: true,
  },
});
const checklistItems = ref([]);

const loading = ref(true);
const getChecklistData = async () => {
  const items = await api.getSponsorChecklist(props.id);
  checklistItems.value = items;
  loading.value = false;
}
getChecklistData();
const emits = defineEmits(["close"]);

const onSave = async () => {
  loading.value = true;
  await api.patchSponsorChecklist(props.id, checklistItems._rawValue);
  emits("close");
};
</script>