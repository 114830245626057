<template>
  <div>
    <div style="display: flex; align-items: center; gap: 20px">
      <h1 style="color: #a5b4bf">Template Builder</h1>
      <n-button :disabled="showNewCard" @click="showNewCard = !showNewCard"
        >Add Item</n-button
      >
    </div>
    <n-scrollbar x-scrollable>
      <div
        style="
          display: flex;
          flex-direction: column;
          gap: 20px;
          flex-wrap: wrap;
          width: 20rem;
          height: 600px;
        "
      >
        <n-card
          v-for="item in unit.checklist.items"
          :key="item"
          style="display: flex"
        >
          <template #header>
            <h5>{{ item.title }}</h5>
          </template>
          <template #header-extra
            ><n-tooltip trigger="hover"
              ><template #trigger
                ><n-icon size="20"><InfoOutlined /></n-icon></template
              >{{ item.description }}</n-tooltip
            ><n-button @click="() => deleteItems(item.id)" type="text"
              ><n-icon size="20" color="red"
                ><DeleteOutlineFilled /></n-icon></n-button
          ></template>
        </n-card>
        <n-card v-if="showNewCard">
          <div style="display: flex; flex-direction: column; gap: 10px">
            <NInput v-model:value="newItemValues.title" placeholder="Title" />
            <NInput
              v-model:value="newItemValues.description"
              placeholder="Description"
              type="textarea"
            />
            <NButton @click="handleNewItem">Submit</NButton>
          </div>
        </n-card>
      </div>
    </n-scrollbar>
  </div>
</template>

<script setup>
import { useUnitStore } from "@/store/unitStore";
import { useAuthStore } from "@/store/authStore";
import { useSponsorStore } from "@/store/sponsorshipsStore";
import { ref } from "vue";
import { NCard, NButton, NInput, NScrollbar, NIcon, NTooltip } from "naive-ui";
import { DeleteOutlineFilled, InfoOutlined } from "@vicons/material";
import { uuid4 as randomUUID } from "uuid4";
const unit = useUnitStore();
const auth = useAuthStore();
const sponsor = useSponsorStore();
const showNewCard = ref(false);

const newItemValues = ref({
  title: "",
  description: "",
  id: randomUUID(),
});

const deleteItems = async (id) => {
  const fullSet = unit.checklist.items.filter((item) => item.id !== id);
  await unit.updateChecklist(fullSet);
};

const handleNewItem = async () => {
  console.log(newItemValues.value);
  const fullSet = [...unit.checklist.items, newItemValues.value];
  await unit.updateChecklist(fullSet);
  newItemValues.value = {
    title: "",
    description: "",
    id: randomUUID(),
  };
  showNewCard.value = false;
};
</script>