import axios from "axios";
import { useKeycloak } from "@baloise/vue-keycloak";
import { useUnitStore } from "@/store/unitStore";
import { useSponsorStore } from "@/store/sponsorshipsStore";

const Client = {
  api: null,
  provider: (type = null) => {
    if (Client.api && !type) {
      return Client.api;
    }
    const { isAuthenticated, token } = useKeycloak();
    if (isAuthenticated.value) {
      if (process.env.VUE_APP_ENV === "development") {
        console.log("token:", token.value);
      }
      Client.api = axios.create({
        baseURL:
          process.env.VUE_APP_ENV === "development"
            ? process.env.VUE_APP_DEV_API_SERVER_PORT
            : "https://spawner.devilops.dso.mil/api",
        headers: {
          Authorization: `Bearer ${token.value}`,
          "Access-Control-Allow-Origin":
            process.env.VUE_APP_ENV === "development"
              ? "*"
              : "https://spawner.devilops.dso.mil",
        },
        responseType: type ? type : "json",
      });
      return Client.api;
    }
    return Error("Not Authenticated");
  },
  getSponsorshipsByUnit: async (unit) => {
    try {
      const { data } = await Client.provider().get(
        `/sponsorships?newUnit=${unit}`
      );
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  getUpdatesBySponsorshipId: async (sponsorshipId) => {
    try {
      const { data } = await Client.provider().get();
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  getAllUnits: async () => {
    try {
      const { data } = await Client.provider().get("/unit");
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  getUnitById: async (unitID) => {
    try {
      const { data } = await Client.provider().get(`/unit/${unitID}`);
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  postSponsorship: async (sponsorshipData) => {
    const sponsors = useSponsorStore();
    try {
      const response = await Client.provider().post(
        "/sponsorships",
        { ...sponsorshipData, unitId: sponsors.unitId }
      );
      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  postBulkSponsorship: async (sponsorshipData) => {
    const { decodedToken } = useKeycloak();
    const sponsor = useSponsorStore();
    try {
      const response = await Client.provider().post("/sponsorships/bulk", {
        items: sponsorshipData,
        createdBy: decodedToken.value.email,
        unitId: sponsor.unitId,
      });
      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  postUpdate: async (id, updateData) => {
    try {
      const { data } = await Client.provider().post(
        `/sponsorships/${id}/comment`,
        updateData
      );
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  postUnit: async (unitData) => {
    try {
      const { data } = await Client.provider().post("/unit", unitData);
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  patchSponsorship: async (id, sponsorshipData) => {
    try {
      const response = await Client.provider().patch(
        `/sponsorships/${id}`,
        sponsorshipData
      );
      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  patchUnit: async (id, unitData) => {
    try {
      const { data } = await Client.provider().patch(`/unit/${id}`, unitData);
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  deleteSponsorship: async (id) => {
    try {
      const { data } = await Client.provider().delete(`/sponsorships/${id}`);
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  deleteUnit: async (id) => {
    try {
      const { data } = await Client.provider().delete(`/unit/${id}`);
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  createGroup: async (name) => {
    try {
      const { data } = await Client.provider().post(
        `/account/group/new?name=${name}`
      );
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  addUserToGroup: async (email, groupId) => {
    try {
      const { data } = await Client.provider().put(`/group/user/new`, {
        email,
        groupId,
      });
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  getUserPermissions: async () => {
    try {
      const { data } = await Client.provider().get(`/account/group/roles`);
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  updateUserPermissions: async (userId, currentGroup, futureGroup) => {
    try {
      const { data } = await Client.provider().put("/account/group/user", {
        currentGroup,
        futureGroup,
        userId,
      });
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  updateUserStatus: async (userData) => {
    try {
      const { data } = await Client.provider().patch(`/user`, userData);
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  getLogs: async () => {
    try {
      const { data } = await Client.provider().get(`/log`);
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  getCurrentUser: async (email) => {
    try {
      const { data } = await Client.provider().get(`/user/${email}`);
      return data[0];
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  getAllUsers: async () => {
    try {
      const { data } = await Client.provider().get(`/user`);
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  updateUserProfile: async (userData) => {
    try {
      const { data } = await Client.provider().post(`/user`, userData);
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  getUnitUsers: async (unitId) => {
    try {
      const { data } = await Client.provider().get(`/user/unit/${unitId}`);
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  getUnitUsersPermissions: async (unitGroupId) => {
    try {
      const { data } = await Client.provider().get(
        `/account/group/${unitGroupId}`
      );
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  transferUnitRequest: async (requestData) => {
    try {
      const { data } = await Client.provider().patch(
        `/user/transfer`,
        requestData
      );
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  deleteUsersBulk: async (userIds, groupId) => {
    const changes = {
      status: "",
      approved: false,
      associatedUnit: "",
      permissionGroupID: groupId,
    };
    userIds.forEach(async (email) => {
      try {
        const { data } = await Client.provider().patch(`/user`, {
          ...changes,
          email,
        });
        return data;
      } catch (error) {
        console.log(error);
        return error;
      }
    });
  },
  sendFeedback: async (feedbackData) => {
    try {
      const { data } = await Client.provider().post("/feedback", feedbackData);
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  getUnitChecklist: async (unitId) => {
    try {
      const { data } = await Client.provider().get(`/unit/${unitId}/checklist`);
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  updateUnitChecklist: async (unitId, checklistData) => {
    const { decodedToken } = useKeycloak();
    const { email } = decodedToken.value;
    try {
      const { data } = await Client.provider().patch(
        `/unit/${unitId}/checklist`,
        {
          lastModifiedBy: email,
          items: checklistData,
        }
      );
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  downloadUserGuide: async () => {
    try {
      const response = await Client.provider()(
        {
          method: "GET",
          url: `/static/spawner-user-guide`,
          responseType: "blob",
          headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          }
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]), { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "spawner-user-guide.pptx");
      link.click();
    } catch (error) {
      console.error(error);
    }
  },
  getSponsorChecklist: async (sponsorId) => {
    const sponsors = useSponsorStore();
    const { decodedToken } = useKeycloak();
    const { email } = decodedToken.value;
    try {
      const { data } = await Client.provider().get(
        `/sponsorships/${sponsorId}/checklist?unitId=${sponsors.unitId}&lastModifiedBy=${email}`
      );
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  patchSponsorChecklist: async (sponsorId, checklistData) => {
    const { decodedToken } = useKeycloak();
    const { email } = decodedToken.value;
    try {
      await Client.provider().patch(
        `/sponsorships/${sponsorId}/checklist`,
        { items: checklistData, lastModifiedBy: email }
      );
    } catch (error) {
      console.error(error);
      return error;
    }
  },
};

export default Client;
