// // {
// "id": 1,
//     "memberName": "Sigvard Flade",
//         "unit": "8 IS",
//             "date": "11/17/2022",
//                 "afsc": "1N1", "rank":
// "AB", "lastUpdate": "1/2/2023",
//     "sponsorshipDate": "11/27/2022",
//         "RNLTD": "10/11/2023",
//             "sponsorName": "William Getman"
// }

const dateGenByRange = (start, end) => {
    const date = new Date(new Date(start).getTime() + Math.random() * (new Date(end).getTime() - new Date(start).getTime()));
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    const formattedDate = `${month}/${day}/${year}`;

    return formattedDate;
}

const genRank = () => {
    const rank = ['AB', 'Amn', 'SrA', 'SSgt', 'TSgt', 'MSgt']
    return rank[Math.floor(Math.random() * rank.length)]
}

const genAFSC = () => {
    const afsc = ['1N1', '1N2', "1N4", "1N2", "1N0"]
    return afsc[Math.floor(Math.random() * afsc.length)]

}

const genName = () => {
    const first = ['William', 'John', 'James', 'Charles', 'George', 'Frank', 'Joseph', 'Thomas', 'Henry', 'Robert', 'Edward', 'Harry', 'Walter', 'Arthur', 'Fred', 'Albert', 'Samuel', 'David', 'Louis', 'Joe', 'Charlie', 'Clarence', 'Richard', 'Andrew', 'Daniel', 'Ernest', 'Will', 'Jesse', 'Oscar', 'Lewis', 'Peter', 'Benjamin']
    const last = ['Smith', 'Johnson', 'Williams', 'Jones', 'Brown', 'Davis', 'Miller', 'Wilson', 'Moore', 'Taylor', 'Anderson', 'Thomas', 'Jackson', 'White', 'Harris', 'Martin', 'Thompson', 'Garcia', 'Martinez', 'Robinson', 'Clark', 'Rodriguez', 'Lewis', 'Lee', 'Walker', 'Hall', 'Allen', 'Young', 'Hernandez', 'King', 'Wright', 'Lopez', 'Hill', 'Scott', 'Green', 'Adams', 'Baker', 'Gonzalez', 'Nelson', 'Carter', 'Mitchell', 'Perez', 'Roberts', 'Turner', 'Phillips', 'Campbell', 'Parker', 'Evans', 'Edwards', 'Collins']
    return `${first[Math.floor(Math.random() * first.length)]} ${last[Math.floor(Math.random() * last.length)]}`
}

const genSponsor = () => {
    const name = ["Mathieu Sherburne", "William Getman"]
    return name[Math.floor(Math.random() * name.length)]
}

const genUnit = () => {
    const units = ["8 IS", "692 ISRG", "11 IS", "315 TRS", "316 TRS"]
    return units[Math.floor(Math.random() * units.length)]
}

const getBase = (unit) => {
    unit = unit.split(" ")[0]
    if (unit === "8" || unit === "692") {
        return "Hickam AFB"
    }
    else if (unit === "315" || unit === "316") {
        return "Goodfellow AFB"
    }
    else if (unit === "11") {
        return "Hurlbert Field AFB"
    }
}

const genUpdates = () => {
    const updates = ["Just received orders for my PCS from [unit name] to [new location].",
        "Started packing up my belongings for the move.",
        "Turned in my housing paperwork and started looking for a place to live at my new duty station.",
        "Made travel arrangements for my family and me to get to our new location.",
        "Attending pre-move briefings to prepare for the PCS process.",
        "Completed a home inventory and turned it in to the transportation office.",
        "Started saying goodbye to friends and colleagues at [unit name].",
        "Participated in a 'hail and farewell' event to honor outgoing and incoming members of the unit.",
        "Turned in my equipment and turned over any duties or responsibilities to my replacement.",
        "Attended a farewell ceremony for my unit.",
        "Packed up my household goods and shipped them to my new duty station.",
        "Drove my personally owned vehicle (POV) to my new location.",
        "Arrived at my new duty station and checked into temporary lodging.",
        "Met with my new unit's leadership and received an orientation to the area.",
        "Started unpacking and settling into my new home.",
        "Enrolled my family in the local school district and made arrangements for childcare.",
        "Located the nearest grocery store, gas station, and other amenities.",
        "Toured my new duty station and learned about the local area.",
        "Started exploring recreational and leisure activities in the area.",
        "Completed my in-processing and officially checked into my new unit."]
    updates.sort(() => Math.random() - 0.5);
    return [updates[0], updates[1]]
}

const getData = (amount) => {
    const out = []
    for (let i = 0; i < amount; i++) {
        const unit = genUnit()
        const updates = genUpdates()
        let lastUpdate
        if (i % 2 === 0) {
            lastUpdate = dateGenByRange(new Date().getTime() - (7 * 24 * 60 * 60 * 1000), new Date().getTime() - (1 * 24 * 60 * 60 * 1000))
        }else {
            lastUpdate = dateGenByRange(new Date(2022, 10, 1), new Date(2022, 11, 31))
        }
        const data = {
            id: i,
            memberName: genName(),
            unit: unit,
            date: lastUpdate,
            afsc: genAFSC(),
            rank: genRank(),
            lastUpdate: lastUpdate,
            sponsorshipDate: dateGenByRange(new Date(2022, 0, 1), new Date(2022, 10, 31)),
            RNLTD: dateGenByRange(new Date(2023, 1, 2), new Date(2023, 4, 31)),
            sponsorName: genSponsor(),
            base: getBase(unit),
            updates: [{ date: lastUpdate, updateText: updates[0] }, { date: dateGenByRange(new Date(lastUpdate).getTime() - (7 * 24 * 60 * 60 * 1000), new Date(lastUpdate).getTime() - (3 * 24 * 60 * 60 * 1000)), updateText: updates[1] }]
        }
        out.push(data)
    }
    return out
}


module.exports.getData = getData