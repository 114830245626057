<template>
  <div v-if="props?.data && props.data.length > 0">
    <div style="display: flex; gap: 20px">
      <n-button @click="showFilters = !showFilters" :focusable="false">Show Filters</n-button>
      <n-button :focusable="false" type="secondary" v-for="filter in filters" @click="filter.value = filter.initialValue"
        :key="filter.label">Reset {{ filter.label }}</n-button>
    </div>
    <div style="
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin-top: 20px;
                  ">
      <apexchart v-if="series.length > 0" width="400" type="donut" :options="barOptions" :series="series" />
      <n-h2 v-else>No Rank Data</n-h2>
      <apexchart v-if="afscData.length > 0" width="400" type="donut" :options="donutOptions" :series="afscData" />
      <n-h2 v-else>No AFSC Data</n-h2>

      <apexchart v-if="housingSeries.length > 0" width="400" type="donut" :options="housingOptions"
        :series="housingSeries" />
      <n-h2 v-else>No Housing Data</n-h2>
      <apexchart v-if="updateData.length > 0" width="400" type="donut" :options="updateOptions" :series="updateData" />
      <n-h2 v-else>No Update Data</n-h2>
      <apexchart v-if="flightData.length > 0" width="400" type="donut" :options="flightOptions" :series="flightData" />
      <n-h2 v-else>No Flight Data</n-h2>
    </div>
    <n-drawer placement="right" :width="500" v-model:show="showFilters">
      <n-drawer-content title="Filters">
        <div style="display: flex; flex-direction: column">
          <n-form-item label="Time Filter">
            <n-select v-model:value="filters.dayChosen.value" :options="selectOptions" />
          </n-form-item>
          <n-form-item label="Rank Filter">
            <n-select :options="rankFilter" v-model:value="filters.rankChosen.value" />
          </n-form-item>
          <n-form-item label="AFSC Filter">
            <n-select :options="afscSelectOptions" v-model:value="filters.afscChosen.value" />
          </n-form-item>
          <n-form-item label="Flight Filter">
            <n-select :options="flightFilter" v-model:value="filters.flightChosen.value" />
          </n-form-item>
        </div>
      </n-drawer-content>
    </n-drawer>
  </div>
  <div v-else>
    <n-h2 align="left">
      <n-text type="warning"> No Data to visualize. </n-text>
    </n-h2>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import {
  NSelect,
  NDrawer,
  NDrawerContent,
  NButton,
  NFormItem,
  NH2,
  NText,
} from "naive-ui";
const iterance = ref(0);
const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
});
const cats = ref([]);
const showFilters = ref(false);
const selectOptions = [
  { label: "7 Days", value: 7 },
  { label: "14 Days", value: 14 },
  { label: "30 Days", value: 30 },
  { label: "60 Days", value: 60 },
  { label: "90 Days", value: 90 },
  { label: "All", value: 3600 },
];

const categorizeByMonth = (data, key) => {
  let sorted = data.sort((a, b) => {
    return new Date(a.rnltd) - new Date(b.rnltd);
  });
  let outData = {};
  const cats = new Array(new Set(sorted.map(item => item[key])));
  sorted = sorted.map((item) => {
    const date = new Date(item.rnltd);
    const month = date.getMonth();
    const year = date.getFullYear();
    const monthYear = `${month}-${year}`;
    return {
      ...item,
      monthYear,
    };
  })
  cats.forEach((cat) => {
    let filtered = sorted.filter((item) => item[key] === cat);

  })
}

const filters = ref({
  dayChosen: { label: "Time Filter", value: 3600, initialValue: 3600 },
  rankChosen: { label: "Rank Filter", value: "All", initialValue: "All" },
  afscChosen: { label: "AFSC Filter", value: "All", initialValue: "All" },
  flightChosen: { label: "Flight Filter", value: "All", initialValue: "All" },
});
const dayChosen = ref(90);
const originalCats = ref([]);
const organizationSponsorData = computed(() => {
  const afterDate = props.data.filter((row) => {
    return (
      Math.abs(new Date(row.rnltd).getTime() - new Date().getTime()) <
      filters.value.dayChosen.value * 24 * 1000 * 3600
    );
  });
  let afterRank = afterDate;

  if (filters.value.rankChosen.value === "NCOs") {
    afterRank = afterDate.filter((row) => {
      return (
        row.memberRank === "SSgt" ||
        row.memberRank === "TSgt" ||
        row.memberRank === "MSgt" ||
        row.memberRank === "SMSgt" ||
        row.memberRank === "CMSgt"
      );
    });
  }
  if (filters.value.rankChosen.value === "Amn") {
    afterRank = afterDate.filter((row) => {
      return (
        row.memberRank === "AB" ||
        row.memberRank === "Amn" ||
        row.memberRank === "A1C" ||
        row.memberRank === "SrA"
      );
    });
  }
  // return afterRank;
  let afterAfsc = afterRank;
  if (filters.value.afscChosen.value !== "All") {
    afterAfsc = afterAfsc.filter((row) => {
      return row.afsc === filters.value.afscChosen.value;
    });
  }
  let newCats = [];
  afterAfsc.forEach((row) => {
    if (!newCats.includes(row.afsc)) {
      newCats.push(row.afsc);
    }
  });
  cats.value = newCats;
  if (iterance.value === 0) {
    originalCats.value = newCats;
    iterance.value++;
  }

  let afterFlight = afterAfsc;
  if (filters.value.flightChosen.value !== "All") {
    afterFlight = afterFlight.filter((row) => {
      return row.flight === filters.value.flightChosen.value;
    });
  }
  return afterFlight;
});

const flightCats = computed(() => {
  const data = [];
  organizationSponsorData.value.forEach((row) => {
    if (!data.includes(row.flight) && row?.flight) {
      data.push(row.flight);
    }
  });
  return [...data, "Unknown"];
});

console.log(flightCats.value);

const flightData = computed(() => {
  const data = [];
  flightCats.value.forEach((cat) => {
    data.push(
      cat === "Unknown"
        ? organizationSponsorData.value.filter((row) => !row?.flight).length
        : organizationSponsorData.value.filter((row) => row.flight === cat)
          .length
    );
  });
  return data;
});

const flightFilter = computed(() => {
  const options = [];
  flightCats.value.forEach((cat) => {
    if (cat !== "Unknown") {
      options.push({ label: cat, value: cat });
    }
  });
  options.push({ label: "All", value: "All" });
  return options;
});
const afscSelectOptions = computed(() => {
  const options = [];
  originalCats.value.forEach((cat) => {
    options.push({ label: cat, value: cat });
  });
  options.push({ label: "All", value: "All" });
  return options;
});
const rankCats = computed(() => {
  const data = [];
  organizationSponsorData.value.forEach((row) => {
    if (!data.includes(row.memberRank) && row?.memberRank) {
      data.push(row.memberRank);
    }
  });
  return data;
});
organizationSponsorData.value.sort((a, b) => {
  const ranks = [
    "AB",
    "Amn",
    "A1C",
    "SrA",
    "SSgt",
    "TSgt",
    "MSgt",
    "SMSgt",
    "CMSgt",
    "2Lt",
    "1Lt",
    "Capt",
    "Maj",
    "Lt Col",
    "Col",
    "Brig Gen",
    "Maj Gen",
    "Lt Gen",
    "Gen",
  ];
  return ranks.indexOf(a.memberRank) - ranks.indexOf(b.memberRank);
});

// const cats = computed(() => {
//   const data = [];
//   organizationSponsorData.value.forEach((row) => {
//     if (!data.includes(row.afsc) && row?.afsc) {
//       data.push(row.afsc);
//     }
//   });
//   return data;
// });
// const cats = [];

const rankFilter = computed(() => {
  return [
    { label: "All", value: "All" },
    { label: "NCOs", value: "NCOs" },
    { label: "Amn", value: "Amn" },
  ];
});

const incomingCount = computed(() => {
  return findEntriesWithinDayRange(
    organizationSponsorData.value,
    dayChosen.value
  );
});
const donutOptions = computed(() => {
  return {
    title: {
      text: "Incoming Airman by AFSC",
      style: {
        color: "#fff",
      },
    },
    chart: {
      id: "Incoming Airman by AFSC Donut Chart",
    },
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
            },
          },
        },
      },
    },
    dataLabels: {
      formatter(val, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex];
        return [name, val.toFixed(1) + "%"];
      },
    },
    labels: cats.value,
  };
});

const flightOptions = computed(() => {
  return {
    title: {
      text: "Incoming Airman by Flight",
      style: {
        color: "#fff",
      },
    },
    legend: {
      show: false,
    },
    chart: {
      id: "incoming_airmen_by_flight",
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
            },
          },
        },
      },
    },
    dataLabels: {
      formatter(val, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex];
        return [name, val.toFixed(1) + "%"];
      },
    },
    labels: flightCats.value,
  };
});
const barOptions = computed(() => {
  return {
    title: {
      text: "Incoming Airman by Rank",
      style: {
        color: "#fff",
      },
    },
    legend: {
      show: false,
    },
    chart: {
      id: "incoming_airmen_by_rank",
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
            },
          },
        },
      },
    },
    dataLabels: {
      formatter(val, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex];
        return [name, val.toFixed(1) + "%"];
      },
    },
    labels: rankCats.value,
  };
});
const housingCats = computed(() => {
  const data = [];
  organizationSponsorData.value.forEach((row) => {
    if (!data.includes(row?.projectedHousing) && row?.projectedHousing) {
      data.push(row.projectedHousing);
    }
  });
  return data;
});
const housingOptions = computed(() => {
  return {
    title: {
      text: "Incoming Airman Housing",
      style: {
        color: "#fff",
      },
    },
    legend: {
      show: false,
    },
    chart: {
      id: "incoming_airmen_housing",
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
            },
          },
        },
      },
    },
    dataLabels: {
      formatter(val, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex].replace(
          "Housing",
          ""
        );
        return [name, val.toFixed(1) + "%"];
      },
    },
    labels: housingCats.value,
  };
});
const afscData = computed(() => {
  const data = [];
  for (let i = 0; i < cats.value.length; i++) {
    data[i] = [];
    organizationSponsorData.value.forEach((row) => {
      if (row.afsc == cats.value[i]) {
        data[i]++;
      }
    });
  }
  return data;
});
const daysBetween = (date1, date2) => {
  const oneDay = 24 * 60 * 60 * 1000;
  return Math.round(Math.abs((date1 - date2) / oneDay));
};
organizationSponsorData.value.forEach((row) => {
  row.authoritiveUpdateDate =
    row?.comments?.length > 0 ? row.comments[0].createdAt : row.lastModifiedAt;
});
const updatedCats = computed(() => {
  const data = [];
  organizationSponsorData.value.forEach((row) => {
    if (daysBetween(new Date(row.authoritiveUpdateDate), new Date()) >= 6) {
      if (!data.includes("Late")) {
        data.push("Late");
      }
    } else {
      if (!data.includes("Current")) {
        data.push("Current");
      }
    }
  });
  return data;
});

const updateOptions = computed(() => {
  return {
    title: {
      text: "Unit Update Status",
      style: {
        color: "#fff",
      },
    },
    chart: {
      id: "Unit Update Donut Chart",
    },
    // theme: {
    //   palette: "palette9",
    // },
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
            },
          },
        },
      },
    },
    dataLabels: {
      formatter(val, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex];
        return [name, val.toFixed(1) + "%"];
      },
    },
    labels: updatedCats.value,
  };
});
const updateData = computed(() => {
  const data = [];
  updatedCats.value.forEach((type, index) => {
    data[index] = 0;
    organizationSponsorData.value.forEach((row) => {
      if (
        type === "Current" &&
        daysBetween(new Date(row.authoritiveUpdateDate), new Date()) < 6
      ) {
        data[index]++;
      } else if (
        type === "Late" &&
        daysBetween(new Date(row.authoritiveUpdateDate), new Date()) >= 6
      ) {
        data[index]++;
      }
    });
  });
  return data;
});

const rankData = computed(() => {
  const data = [];
  rankCats.value.forEach((type, index) => {
    data[index] = 0;
    organizationSponsorData.value.forEach((row) => {
      if (row.memberRank == type) {
        data[index]++;
      }
    });
  });

  return data;
});

const housingData = computed(() => {
  const data = [];
  housingCats.value.forEach((type, index) => {
    data[index] = 0;
    organizationSponsorData.value.forEach((row) => {
      if (row?.projectedHousing == type) {
        data[index]++;
      }
    });
  });

  return data;
});

const series = computed(() => rankData.value);
const housingSeries = computed(() => housingData.value);
const findEntriesWithinDayRange = (data, days) => {
  const millisecondsToDays = (date) => {
    return date / (1000 * 60 * 60 * 24);
  };
  const today = new Date();
  const newData = [];
  data.forEach((row) => {
    if (millisecondsToDays(Math.abs(new Date(row.rnltd) - today)) <= days) {
      newData.push(row);
    }
  });
  return newData.length;
};
</script>

<style scoped>
.incoming {
  color: #fdfeff;
  width: 20%;
  text-align: center;
}

:deep(.apexcharts-text) {
  fill: #f6fbff;
}

:deep(.apexcharts-legend-text) {
  color: #ffffff !important;
}
</style>
