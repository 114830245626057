<template>
  <div style="width: 800px; max-width: 90vw">
    <n-card title="New Update">
      <n-form :model="updateForm">
        <div style="display: flex; gap: 10px">
          <n-form-item label="Projected Housing">
            <n-select
              :options="projectedHousingValues"
              v-model:value="updateForm.sponsorshipEdits.projectedHousing"
            />
          </n-form-item>
          <n-form-item label="Number of Dependants">
            <n-input
              type="number"
              v-model:value="updateForm.sponsorshipEdits.dependentCount"
            />
          </n-form-item>
        </div>
        <n-form-item label="Update">
          <n-input
            v-model:value="updateForm.updateText"
            type="textarea"
            placeholder="Update"
          />
        </n-form-item>
        <!-- {{ updateForm.updateText }} -->
        <n-form-item>
          <div style="display: flex; gap: 20px">
            <n-button :focusable="false" @click="submitUpdate">
              Submit
            </n-button>
            <n-button @click="assertUpdate">Assert Update</n-button>
            <n-button type="primary" @click="earlyArrival"
              >Early Arrival</n-button
            >
          </div>
        </n-form-item>
      </n-form>
    </n-card>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { NCard, NForm, NFormItem, NInput, NButton, NSelect } from "naive-ui";
import api from "@/api";
import { useSponsorStore } from "@/store/sponsorshipsStore";
import { useKeycloak } from "@baloise/vue-keycloak";
const sponsors = useSponsorStore();

const emit = defineEmits(["close"]);
const projectedHousingValues = [
  { label: "Dorms", value: "Dorms" },
  { label: "On Base Housing", value: "On Base Housing" },
  { label: "Off Base Housing", value: "Off Base Housing" },
];

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const updateForm = ref({
  sponsorshipEdits: {
    projectedHousing: props.data?.projectedHousing || "",
    dependentCount: props.data?.dependentCount || null,
  },
  updateText: "",
});

const assertUpdate = () => {
  const sponsorshipUpdates = updateForm.value.sponsorshipEdits;
  let changes = [];
  const keys = Object.keys(sponsorshipUpdates);
  keys.forEach((key) => {
    if (sponsorshipUpdates[key] !== props.data[key]) {
      const obj = {};
      obj[key] = sponsorshipUpdates[key];
      changes.push(obj);
    }
  });
  if (changes.length > 0) {
    let text = "";
    changes.forEach((change) => {
      const key = Object.keys(change)[0];
      text += `(${key} update: ${change[key]})`;
    });
    updateForm.value.updateText += ` ${text}`;
  }
};
const { decodedToken } = useKeycloak();
const submitUpdate = async () => {
  const update = updateForm.value.updateText;
  const data = {
    update,
    createdBy: decodedToken.value.preferred_username,
  };
  try {
    const response = await api.postUpdate(props.data.id, data);
    updateForm.value.updateText = "";
  } catch (error) {
    console.log(error);
  }

  const sponsorshipUpdates = updateForm.value.sponsorshipEdits;
  const keys = Object.keys(sponsorshipUpdates);
  keys.forEach((key) => {
    if (sponsorshipUpdates[key] === "") {
      delete sponsorshipUpdates[key];
    }
  });
  if (Object.keys(sponsorshipUpdates).length > 0) {
    try {
      sponsorshipUpdates.createdBy = decodedToken.value.preferred_username;
      const response = await api.patchSponsorship(
        props.data.id,
        sponsorshipUpdates
      );
      updateForm.value.sponsorshipEdits = {
        projectedHousing: "",
        dependentCount: null,
      };
    } catch (error) {
      console.log(error);
    }
  }
  sponsors.getSponsorData();
  emit("close");
};

const earlyArrival = async () => {
  let updates = updateForm.value.sponsorshipEdits;
  updates.rnltd = new Date().toISOString();
  updates.createdBy = decodedToken.value.preferred_username;
  try {
    const response = await api.patchSponsorship(props.data.id, updates);
    const updateResponse = await api.postUpdate(props.data.id, {
      update: updateForm.value.updateText,
      createdBy: decodedToken.value.preferred_username,
    });
    updateForm.value.sponsorshipEdits = {
      projectedHousing: "",
      dependentCount: null,
    };
  } catch (error) {
    console.log(error);
  }
  emit("close");
};
</script>