import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Err404 from "../Errors/Err404.vue";
import Err403 from "../Errors/Err403.vue";
import Err418 from "../Errors/Err418.vue";
import Organization from "../views/Organization.vue";

export const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    secured: false,
  },
  {
    path: "/org",
    name: "Organization",
    // which is lazy-loaded when the route is visited.
    component: Organization,
    secured: true,
  },
  {
    path: "/unit-request",
    name: "New Unit Request",
    component: () => import("../views/UnitRequest.vue"),
    secured: false,
    show: false,
  },
  {
    path: "/admin",
    name: "",
    component: () => import("../views/AdminScreen.vue"),
    secured: true,
  },
  // {
  //   path: "/release",
  //   name: "Release Notes",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/ReleaseNotes.vue"),
  //   secured: true,
  // },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../views/Settings.vue"),
    secured: false,
  },
  {
    path: "/403",
    name: "",
    component: Err403,
    secured: false,
  },
  {
    path: "/418",
    name: "",
    component: Err418,
    secured: false,
  },
  {
    path: "/:catchAll(.*)",
    name: "",
    component: Err404,
    secured: false,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
