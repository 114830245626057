<template>
  <div
    v-if="sponsors.organizationSponsorData.length === 0 && !sponsors.lastFetch"
  >
    <n-spin />
  </div>
  <div class="home" v-else>
    <div class="tasker-box">
      <h1>Your Taskers</h1>
      <div class="taskers" v-if="sponsors.userSponsorData.length > 0">
        <SponsorshipCard
          v-for="data in sponsors.userSponsorData"
          :key="data.id"
          :data="data"
          editable
        />
      </div>
      <n-h2 v-else align="left" type="warning">
        <n-text type="warning"> No taskers to display. </n-text>
      </n-h2>
    </div>
    <div class="tasker-box extra">
      <n-divider />
    </div>
    <div class="tasker-box">
      <h1>Organization Taskers</h1>
      <div class="taskers" v-if="orgData.length > 0">
        <SponsorshipCard v-for="data in orgData" :key="data.id" :data="data" />
      </div>
      <n-h2 v-else align="left">
        <n-text type="warning"> No taskers to display. </n-text>
      </n-h2>
    </div>
  </div>
</template>

<script setup>
import SponsorshipCard from "@/components/SponsorshipCard.vue";
import { NDivider, NText, NH2, NSpin } from "naive-ui";
import { useSponsorStore } from "@/store/sponsorshipsStore.js";
import { computed } from "vue";
const sponsors = useSponsorStore();
if (!sponsors.lastFetch || sponsors.lastFetch < Date.now() - 1000 * 60 * 5) {
  sponsors.getSponsorData();
}
const orgData = computed(() => {
  const userDataIds = sponsors.userSponsorData.map((row) => row.id);
  console.log(sponsors.organizationSponsorData);
  console.log(
    userDataIds,
    "user ids",
    sponsors.organizationSponsorData,
    "org data"
  );
  const data = sponsors.organizationSponsorData.filter((row) => {
    return !userDataIds.includes(row.id);
  });
  console.log(data);
  return data;
});
document.title = "Dashboard";
</script>

<style scoped>
.home {
  color: #a5b4bf;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 90vw;
  margin: 0 auto 2rem auto;
}
.tasker-box h1 {
  text-align: left;
}
.extra {
  max-width: 100%;
}
.taskers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  margin-left: 1rem;
}
</style>
