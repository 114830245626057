<template>
  <n-card style="width: 800px; max-width: 80vw">
    <h1>Edit Sponsorship</h1>
    <n-spin :show="processing">
      <n-form :model="form" :rules="rules">
        <n-form-item label="Member Name" path="memberName">
          <n-input placeholder="Full Name" v-model:value="form.memberName" />
        </n-form-item>
        <n-form-item label="Member Email" path="memberEmail">
          <n-input placeholder="Enter Email" v-model:value="form.memberEmail" />
        </n-form-item>
        <n-form-item label="Member AFSC" path="afsc">
          <n-input placeholder="Enter AFSC" v-model:value="form.afsc" />
        </n-form-item>
        <n-form-item label="Flight" path="flight">
          <n-input placeholder="Enter Flight" v-model:value="form.flight" />
        </n-form-item>
        <n-form-item label="Member Rank" path="rank">
          <n-select
            placeholder="Enter Rank"
            v-model:value="form.rank"
            :options="rankOptions"
          />
        </n-form-item>
        <n-form-item label="Losing Unit" path="unit">
          <n-input
            type="number"
            placeholder="Enter Unit"
            v-model:value="form.unit"
          />
        </n-form-item>
        <n-form-item label="Sponsor Name" path="sponsorName">
          <n-input placeholder="Full Name" v-model:value="form.sponsorName" />
        </n-form-item>
        <n-form-item label="Sponsor Email" path="sponsorEmail">
          <n-input
            placeholder="Enter Email"
            v-model:value="form.sponsorEmail"
          />
        </n-form-item>
        <n-form-item label="RNLTD" path="rnltd">
          <n-date-picker v-model:value="form.rnltd" />
        </n-form-item>
        <n-button :focusable="false" @click="submitSponsorship"
          >Submit Changes</n-button
        >
      </n-form>
    </n-spin>
  </n-card>
</template>

<script setup>
import {
  NCard,
  NButton,
  NForm,
  NFormItem,
  NSpin,
  NInput,
  NSelect,
  NDatePicker,
  useMessage,
} from "naive-ui";
import { ref } from "vue";
import api from "@/api";
import { useSponsorStore } from "@/store/sponsorshipsStore";
import { useKeycloak } from "@baloise/vue-keycloak";
const message = useMessage();
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});
const { decodedToken } = useKeycloak();
const processing = ref(false);
const emailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.mil$/;
const sponsors = useSponsorStore();
const form = ref({
  memberName: props.data.memberName,
  memberEmail: props.data.memberEmail,
  rank: props.data.memberRank,
  afsc: props.data.afsc,
  sponsorName: props.data.sponsorName,
  sponsorEmail: props.data.sponsorEmail,
  unit: props.data.oldUnit,
  rnltd: new Date(props.data.rnltd).getTime(),
});

const emit = defineEmits(["close"]);
const rankOptions = [
  { label: "AB", value: "AB" },
  { label: "Amn", value: "Amn" },
  { label: "A1C", value: "A1C" },
  { label: "SrA", value: "SrA" },
  { label: "SSgt", value: "SSgt" },
  { label: "TSgt", value: "TSgt" },
  { label: "MSgt", value: "MSgt" },
];

const rules = {
  memberName: {
    required: true,
  },
  memberEmail: {
    required: true,
    trigger: ["blur", "input"],
    validator: (rule, value) => {
      if (!emailRegex.test(value)) {
        return Error("Please enter a valid .mil email address");
      }
      return true;
    },
  },
  rank: {
    required: true,
  },
  afsc: {
    required: true,
  },
  unit: {
    required: true,
  },
  flight: {
    required: false,
  },
  sponsorName: {
    required: true,
  },
  sponsorEmail: {
    required: true,
    trigger: ["blur", "input"],
    validator: (rule, value) => {
      if (!emailRegex.test(value)) {
        return Error("Please enter a valid .mil email address");
      }
      return true;
    },
  },
  rnltd: {
    required: true,
  },
};
const submitSponsorship = async () => {
  processing.value = true;
  const username = decodedToken.value.preferred_username;
  const data = {
    memberName: form.value.memberName,
    createdBy: username,
    memberEmail: form.value.memberEmail,
    memberRank: form.value.rank,
    afsc: form.value.afsc.toUpperCase(),
    oldUnit: form.value.unit,
    sponsorName: form.value.sponsorName,
    sponsorEmail: form.value.sponsorEmail,
    rnltd: new Date(form.value.rnltd).toISOString(),
    flight: form.value.flight,
  };

  try {
    const response = await api.patchSponsorship(props.data.id, data);
    if (response.status === 200) {
      // data["lastModifiedAt"] = new Date().toISOString();
      // console.log(data);
      // sponsors.editSponsorship(props.id, new Object(data));
      // sponsors.reconfig();
      // sponsors.getSponsorData();
      message.success("Sponsorship submitted successfully");
      form.value = {
        memberName: "",
        memberEmail: "",
        rank: "",
        afsc: "",
        sponsorName: "",
        sponsorEmail: "",
        unit: "",
        flight: "",
        RNLTD: new Date().getTime(),
      };
      processing.value = false;
      emit("close");
    }
  } catch (error) {
    processing.value = false;
    console.error(error);
    message.error(error);
    emit("close");
  }
};
</script>
