import { defineStore } from "pinia";
import dataGenerator from "@/assets/dataGenerator";
import { useAuthStore } from "./authStore";
import client from "@/api";

export const useSponsorStore = defineStore("sponsorships", {
  state: () => ({
    userSponsorData: [],
    organizationSponsorData: [],
    unit: null,
    unitId: null,
    permissionGroupID: null,
    testData: [],
    client: client,
    lastFetch: null,
    userStatus: null,
  }),
  actions: {
    async getSponsorData() {
      const auth = useAuthStore();
      const username = auth.username;
      const sponsorData = await client.getSponsorshipsByUnit(this.unit);
      this.organizationSponsorData = sponsorData;
      const userData = auth.isAdmin
        ? sponsorData
        : sponsorData.filter((el) => el.sponsorEmail === username);
      this.userSponsorData = userData;
      this.lastFetch = new Date();
      return sponsorData;
    },
    editSponsorship(id, sponsorshipData) {
      const ref = this.organizationSponsorData.findIndex(
        (val) => val.id === id
      );
      const entries = Object.entries(this.organizationSponsorData[ref]);

      entries.forEach((key, value) => {
        if (Object.keys(new Object(sponsorshipData)).includes(key)) {
          this.organizationSponsorData[ref][key] = sponsorshipData[key];
        }
        this.reconfig();
      });
    },
    getData(val) {
      this.testData = dataGenerator.getData(val);
    },
    addSponsorship(sponsorshipData) {
      this.organizationSponsorData.push(sponsorshipData);
    },
    reconfig() {
      const { username } = useAuthStore();
      const userData = this.organizationSponsorData.filter(
        (sponsorship) => sponsorship.sponsorEmail === username
      );
      this.userSponsorData = userData;
    },
  },
  getters: {
    clientSet() {
      return this.client ? true : false;
    },
  },
  // persist: {
  //   enabled: true,
  // },
});
