<template>
  <div>
    <div class="org">
      <h1>Organization</h1>
      <div class="left">
        <n-tabs
          animated
          default-value="Sponsorships"
          type="line"
          justify-content="center"
        >
          <n-tab-pane name="Sponsorships">
            <div>
              <div class="buttongroup">
                <n-button
                  v-if="auth.isAdmin"
                  @click="showNewSponsor = !showNewSponsor"
                  :focusable="false"
                >
                  <template #icon>
                    <n-icon :component="PersonAddFilled" />
                  </template>
                  New Sponsorship
                </n-button>
                <n-button
                  :disabled="false"
                  :focusable="false"
                  @click="openUpload"
                  v-if="auth.isAdmin"
                >
                  <template #icon>
                    <n-icon :component="FileUploadFilled" />
                  </template>
                  Upload GAINS
                </n-button>
                <n-button
                  @click="download"
                  :focusable="false"
                  :disabled="!sponsors.organizationSponsorData.length > 0"
                >
                  <template #icon>
                    <n-icon :component="FileDownloadFilled" />
                  </template>
                  Download
                </n-button>
                <n-button
                  @click="deleteRows"
                  :disabled="!checkedRowKeysRef.length > 0"
                  v-if="rowValue"
                  :focusable="false"
                >
                  Delete ({{ checkedRowKeysRef.length }})
                </n-button>
                <div>Total Records: {{ RowData.length }}</div>
                <div style="flex-grow: 1; text-align: right">
                  <n-tooltip>
                    <template #trigger>
                      <n-button
                        @click="refreshSponsorData"
                        :focusable="false"
                        :disabled="loading"
                        secondary
                        circle
                        type="primary"
                      >
                        <n-icon :component="RefreshFilled" :size="25" />
                      </n-button>
                    </template>
                    <span>Refresh table data</span>
                  </n-tooltip>
                </div>
              </div>
              <br />
              <n-data-table
                :row-class-name="rowClass"
                :row-key="rowKey"
                :data="RowData"
                :columns="columns"
                @update:page="changePage"
                @update:checked-row-keys="checkRows"
                :pagination="{ pageSize: 10 }"
                :loading="loading"
              />
            </div>
          </n-tab-pane>
          <n-tab-pane name="Visualize">
            <div>
              <visualize :data="sponsors.organizationSponsorData" />
            </div>
          </n-tab-pane>
          <n-tab-pane name="Permissions">
            <template #tab>
              <n-badge
                :value="units.pendingUserRequests"
                :type="units.pendingUserRequests ? 'error' : 'inherit'"
              >
                <n-text type="inherit">Permission Control</n-text>
              </n-badge>
            </template>
            <!-- prettier-ignore -->
            <div>
          <div style="display: flex; justify-content: space-between; margin-bottom: 1rem;">
              <div style="display: flex; gap: 20px">
              <n-button @click="showNewUserModal = !showNewUserModal">
                <template #icon>
                <n-icon :component="PersonAddFilled" />
              </template>
              Add User to Unit
              </n-button>
              <n-button :disabled="!permCheckedRows.length > 0" v-if="permCheckedRows.length > 0" type="error" @click="handleUserDeletes">
                <template #icon>
                <n-icon :component="DeleteFilled" />
              </template>
                Delete Users ({{permCheckedRows.length}})
              </n-button>
              </div>
            <n-tooltip>
              <template #trigger>
                <n-button
                  @click="refreshUnitUserData"
                  :focusable="false"
                  :disabled="permissionLoading"
                  secondary
                  circle
                  type="primary"
                >
                  <n-icon :component="RefreshFilled" :size="25" />
                </n-button>
              </template>
              <span>Refresh table data</span>
            </n-tooltip>
          </div>
          <n-data-table
            :row-key="permRowKey"
            :data="units.currentUnitUsers"
            :columns="permissionTableColumns"
            :loading="permissionLoading"
            @update:checked-row-keys="checkPermsRows"
            :pagination="{ pageSize: 10 }"
          />
        </div>
          </n-tab-pane>
          <n-tab-pane name="Inprocessing Checklist">
            <schema-builder />
          </n-tab-pane>
        </n-tabs>
      </div>
    </div>
    <n-modal v-model:show="showUpload">
      <upload-card @close-upload="hideUploadModal" />
    </n-modal>
    <n-modal v-model:show="showNewSponsor">
      <new-sponsor-card @close="closeSponsorshipModals" />
    </n-modal>
    <n-modal v-model:show="showEditSponsor">
      <edit-sponsor-card :data="editData" @close="closeSponsorshipModals" />
    </n-modal>
    <n-modal v-model:show="showNewUserModal">
      <n-card style="width: 40%">
        <n-spin :show="permissionLoading">
          <div style="display: flex; gap: 20px">
            <n-input placeholder="Email" v-model:value="newUserEmail" />
            <n-button @click="addUserToGroup">Add User</n-button>
          </div>
        </n-spin>
      </n-card>
    </n-modal>
  </div>
</template>

<script setup>
import NewSponsorCard from "@/components/NewSponsorCard.vue";
import EditSponsorCard from "@/components/EditSponsorshipCard.vue";
import UploadCard from "@/components/UploadCard.vue";
import exportFromJSON from "export-from-json";
import Visualize from "@/components/Visualize.vue";
import { sponsorData } from "@/assets/data";
import SchemaBuilder from "@/components/SchemaBuilder.vue";
import { useSponsorStore } from "@/store/sponsorshipsStore";
import { useUnitStore } from "@/store/unitStore";
import {
  NDataTable,
  NButton,
  NCard,
  NIcon,
  NModal,
  NInput,
  NTabs,
  NTabPane,
  NTooltip,
  NSelect,
  NBadge,
  NText,
  NSpin,
} from "naive-ui";
import {
  PersonAddFilled,
  FileUploadFilled,
  FileDownloadFilled,
  RefreshFilled,
  DeleteFilled,
} from "@vicons/material";
import { useAuthStore } from "@/store/authStore";
import { ref, h, defineComponent, nextTick } from "vue";
import api from "@/api";
import { useMessage } from "naive-ui";
document.title = "Organization";
const message = useMessage();
const sponsors = useSponsorStore();
const units = useUnitStore();
const auth = useAuthStore();
const newSponsorData = ref(sponsorData);
const showNewSponsor = ref(false);
const showNewUserModal = ref(false);
const showUpload = ref(false);
const rowKey = (row) => row.id;
const RowData = ref(sponsors.organizationSponsorData);
const showEditSponsor = ref(false);
const editData = ref({});
const loading = ref(true);
const permissionLoading = ref(true);
const permRowKey = (row) => row.email;
const refreshSponsorData = async () => {
  loading.value = true;
  RowData.value = await sponsors.getSponsorData();
  loading.value = false;
};
const currentDate = new Date();
// on mount check of data has been fetched more than 5 mins ago.
if (
  !sponsors.lastFetch ||
  currentDate - new Date(sponsors.lastFetch) > 5 * 60 * 1000
) {
  refreshSponsorData();
} else {
  loading.value = false;
}
const refreshUnitUserData = async () => {
  permissionLoading.value = true;
  await units.getUnitUserData();
  permissionLoading.value = false;
};

// on mount check of data has been fetched more than 5 mins ago.
if (
  !sponsors.lastPermissionFetch ||
  currentDate - new Date(sponsors.lastPermissionFetch) > 5 * 60 * 1000
) {
  refreshUnitUserData();
} else {
  permissionLoading.value = false;
}
const hideUploadModal = () => {
  refreshSponsorData();
  showUpload.value = false;
};
const closeSponsorshipModals = () => {
  loading.value = true;
  showNewSponsor.value = false;
  showEditSponsor.value = false;
  refreshSponsorData();
  loading.value = false;
};

const newUserEmail = ref("");

const addUserToGroup = async () => {
  permissionLoading.value = true;
  try {
    await api.updateUserProfile({
      email: newUserEmail.value,
      permissionGroupID: sponsors.permissionGroupID,
      associatedUnit: sponsors.unitId,
      approved: true,
      status: "approved",
      justification: `Added by admin.`,
      approvedBy: auth.username,
      approvedAt: new Date().toISOString(),
      createdAt: new Date().toISOString(),
    });
    message.success("User added to unit.");
  } catch (error) {
    console.error(error);
    message.error("Failed to add user to unit.");
    message.error(JSON.stringify(error));
  }
  refreshUnitUserData();
};

const download = () => {
  const data = sponsors.organizationSponsorData;
  const fileName = "sponsorship-" + new Date().toISOString().slice(0, 10);
  let fields = [];
  columns.forEach((column) => {
    if (column != columns[0]) {
      fields.push(column.key);
    }
  });
  const exportType = exportFromJSON.types.csv;
  if (data) exportFromJSON({ data, fileName, exportType, fields });
};
const checkedRowKeysRef = ref([]);
const rowValue = ref(false);
const checkRows = (keys) => {
  if (!keys.length) {
    rowValue.value = false;
  } else {
    rowValue.value = true;
  }
  checkedRowKeysRef.value = keys;
};
const permCheckedRows = ref([]);
const rowPermsValue = ref(false);
const checkPermsRows = (keys) => {
  if (!keys.length) {
    rowPermsValue.value = false;
  } else {
    rowPermsValue.value = true;
  }
  permCheckedRows.value = keys;
};

const handleUserDeletes = async () => {
  permissionLoading.value = true;
  const ids = permCheckedRows.value;
  // add api call to delete users on dynamo
  try {
    const response = await api.deleteUsersBulk(ids, sponsors.permissionGroupID);
    message.success("Users deleted.");
    refreshUnitUserData();
    return response;
  } catch (error) {
    console.error(error);
    message.error("Failed to delete users.");
  }
};
const page = ref(0);
const changePage = (pageNum) => {
  page.value = pageNum - 1;
};
const formatDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  return `${month}/${day}/${year}`;
};
const ShowOrEdit = defineComponent({
  props: {
    value: [String, Number],
    onUpdateValue: [Function, Array],
  },
  setup(props) {
    const isEdit = ref(false);
    const inputRef = ref(null);
    const inputValue = ref(props.value);
    function handleOnClick() {
      isEdit.value = true;
      nextTick(() => {
        inputRef.value.focus();
      });
    }
    function handleChange() {
      props.onUpdateValue(inputValue.value);
      isEdit.value = false;
    }
    return () =>
      h(
        "div",
        {
          onClick: handleOnClick,
        },
        isEdit.value
          ? h(NInput, {
              ref: inputRef,
              value: inputValue.value,
              onUpdateValue: (v) => {
                inputValue.value = v;
              },
              onChange: handleChange,
              onBlur: handleChange,
            })
          : props.value
      );
  },
});

const columns = [
  {
    type: "selection",
  },
  {
    title: "Member Name",
    key: "memberName",
    defaultSortOrder: false,
    sorter: "default",
  },
  {
    title: "Sponsor Name",
    key: "sponsorName",
    sorter: "default",
  },
  {
    title: "Flight",
    key: "flight",
    sorter: "default",
  },
  {
    title: "Unit",
    key: "oldUnit",
    sorter(row1, row2) {
      const numberExtractor = (text) => {
        const number = text.match(/\d+/);
        return number;
      };
      return numberExtractor(row1.oldUnit) - numberExtractor(row2.oldUnit);
    },
  },
  {
    title: "AFSC",
    key: "afsc",
    sorter: "default",
  },
  {
    title: "Projected Housing",
    key: "projectedHousing",
    sorter: "default",
  },
  {
    title: "Dependants",
    key: "dependentCount",
    sorter(row1, row2) {
      const numberExtractor = (text) => {
        if (!text) return 0;
        const number = text.match(/\d+/);
        return number;
      };
      return (
        numberExtractor(row1.dependentCount) -
        numberExtractor(row2.dependentCount)
      );
    },
  },
  {
    title: "Last Updated",
    key: "lastModifiedAt",
    className: "lastUpdate",
    sorter(row1, row2) {
      return new Date(row1.lastModifiedAt) - new Date(row2.lastModifiedAt);
    },
    render: (row, index) => {
      return formatDate(row.lastModifiedAt);
    },
  },
  {
    title: "Sponsorship Date",
    key: "createdAt",
    sorter: "default",
    render: (row, index) => {
      return formatDate(row.createdAt);
    },
  },
  {
    title: "RNLTD",
    key: "rnltd",
    sorter: "default",
    render: (row, index) => {
      return formatDate(row.rnltd);
    },
  },
  {
    title: "Rank",
    key: "memberRank",
    sorter(row1, row2) {
      const ranks = [
        "AB",
        "A1C",
        "SrA",
        "SSgt",
        "TSgt",
        "MSgt",
        "SMSgt",
        "CMSgt",
        "2Lt",
        "1Lt",
        "Capt",
        "Maj",
        "Lt Col",
        "Col",
        "Brig Gen",
        "Maj Gen",
        "Lt Gen",
        "Gen",
      ];
      return ranks.indexOf(row1.memberRank) - ranks.indexOf(row2.memberRank);
    },
  },
  {
    title: "Status",
    key: "status",
    sorter(row1, row2) {
      return (
        (new Date(row1.rnltd).getTime() > Date.now()) -
        (new Date(row2.rnltd).getTime() > Date.now())
      );
    },
    render(row) {
      if (new Date(row.rnltd).getTime() > Date.now()) {
        return <p>INBOUND</p>;
      } else {
        return <p style="color: green">ARRIVED</p>;
      }
    },
  },
];

columns.push({
  title: "Action",
  key: "action",
  render: (row, index) => {
    return h(
      NButton,
      {
        size: "small",
        onClick: () => {
          editData.value = row;
          showEditSponsor.value = true;
        },
      },
      { default: () => "Update" }
    );
  },
});
const deleteRows = () => {
  const keys = checkedRowKeysRef.value;
  const newData = RowData.value.filter((row) => !keys.includes(row.id));
  const oldData = RowData.value.filter((row) => keys.includes(row.id));
  RowData.value = newData;
  oldData.forEach(async (row) => {
    try {
      await api.deleteSponsorship(row.id);
      message.success("Sponsorship deleted!");
    } catch (error) {
      message.error(error);
      console.error(error);
    }
    checkedRowKeysRef.value = [];
    sponsors.getSponsorData();
  });
};

const rowClass = (row) => {
  const millisecondsToDays = (date) => {
    return date / (1000 * 60 * 60 * 24);
  };
  if (millisecondsToDays(Math.abs(new Date(row.lastUpdate) - new Date())) > 7) {
    return "old-data";
  } else {
    return "";
  }
};

const openUpload = () => {
  showUpload.value = true;
};

const updateUserPermissions = async (userId, currentGroup, futureGroup) => {
  permissionLoading.value = true;
  await api.updateUserPermissions(userId, currentGroup, futureGroup);
  refreshUnitUserData();
};

const updateUserStatus = async (userData) => {
  permissionLoading.value = true;
  await api.updateUserStatus(userData);
  refreshUnitUserData();
};

const permissionTableColumns = [
  {
    type: "selection",
  },
  {
    title: "User",
    key: "fullname",
    render: (row) =>
      `${row.username === auth.username ? "⭐ " : ""}${
        row.attributes?.rank[0] &&
        row.attributes?.rank[0].toLowerCase() !== "civilian"
          ? row.attributes.rank[0] + " "
          : ""
      }${row.firstName} ${row.lastName}`,
  },
  { title: "Email", key: "email" },
  { title: "Justification", key: "justification" },
  {
    title: "Status",
    key: "status",
    className: "StatusTd",
    render: (row) => {
      if (row.status === "approved") {
        return row.status.toUpperCase();
      }
      return h(NSelect, {
        value: row.status,
        style: "font-weight: initial;",
        options: [
          {
            label: "Pending",
            value: "pending",
            disabled: row.status === "pending",
          },
          {
            label: "Approved",
            value: "approved",
            disabled: row.status === "approved",
          },
          // {
          //   label: "Denied",
          //   value: "denied",
          //   disabled: row.status === "denied",
          // },
        ],
        onChange: (label, { value: newStatus }) =>
          updateUserStatus({
            approvedBy: auth.username,
            approvedAt: new Date().toISOString(),
            status: newStatus,
            approved: newStatus === "approved" ? true : false,
            email: row.username,
            permissionGroupID: sponsors.permissionGroupID,
          }),
      });
    },
  },
  {
    title: "Role",
    key: "groupName",
    className: "groupName",
    render: (row) => {
      if (!auth.isAdmin && !auth.isSiteAdmin) {
        return row.groupName === "siteAdmin"
          ? "Site Admin"
          : row.groupName === "admin"
          ? "Admin"
          : "Sponsor";
      }
      const levelChecker = (group) => {
        const levels = ["sponsor", "admin", "siteAdmin"];
        return levels.indexOf(group);
      };
      const selfChecker = () => {
        if (auth.isSiteAdmin) {
          return 2;
        }
        if (auth.isAdmin) {
          return 1;
        }
        return 0;
      };
      return h(NSelect, {
        value: row.groupName,
        disabled: levelChecker(row.groupName) > selfChecker(),
        options: [
          {
            label: "Site Admin",
            value: "siteAdmin",
            disabled: !auth.isSiteAdmin || row.groupName === "siteAdmin",
          },
          {
            label: "Admin",
            value: "admin",
            disabled:
              !auth.isAdmin || !auth.isSiteAdmin || row.groupName === "admin",
          },
          {
            label: "Sponsor",
            value: "sponsor",
            disabled: row.groupName === "sponsor",
          },
        ],
        onChange: (label, { value: newGroup }) =>
          updateUserPermissions(row.id, row.groupName, newGroup),
      });
    },
  },
];
</script>

<style scoped>
.left {
  width: 100%;
}
.org {
  color: #a5b4bf;
  text-align: left;
  margin: 0 5% 2rem 5%;
}
.buttongroup {
  display: flex;
  justify-content: left;
  align-items: center;
  color: white;
  gap: 30px;
}
:deep(.old-data .lastUpdate) {
  color: rgb(188, 39, 39) !important;
}
:deep(td.groupName),
:deep(th.groupName) {
  text-align: center !important;
}
:deep(td.StatusTd) {
  text-align: center;
  color: rgb(49, 177, 49);
  font-weight: bold;
}
:deep(.n-base-selection.n-base-selection--disabled .n-base-selection-label) {
  background-color: #9e9e9e !important;
}
:deep(
    .n-base-selection.n-base-selection--disabled
      .n-base-selection-label
      .n-base-selection-input
  ) {
  color: #545454 !important;
}
</style>
