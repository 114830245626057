export default {
  Button: {
    textColorText: "#BBBBBBFF",
    textColorTextHover: "#5095d8",
    borderHover: "1px solid #5095d8",
    textColor: "#BBBBBBFF",
    textColorHover: "#5095d8",
    borderPressed: "1px solid #2F6CA7FF",
    textColorPressed: "#2F6CA7FF",
    rippleColor: "#2B50A2FF",
  },
  Card: {
    titleFontWeight: "bold",
    borderColor: "#2F2F30FF",
    color: "#111010FF",
    colorModal: "#111010FF",
    textColor: "#BBBBBBFF",
    titleTextColor: "#BBBBBBFF",
  },
  Progress: {
    fillColor: "#5095d8",
    textColorCircle: "#BBBBBBFF",
  },
  DataTable: {
    tdColor: "#22272e",
    thColor: "#21212AFF",
    thTextColor: "#BBBBBBFF",
    tdTextColor: "#BBBBBBFF",
    tdColorHover: "#21212AFF",
    thTextColor: "#BBBBBBFF",
    tdTextColor: "#BBBBBBFF",
    thColorHover: "rgba(0,0,0,1)",
    thIconColorActive: "#5095d8",
  },
  Checkbox: {
    borderFocus: "1px solid #5095d8",
    colorChecked: "#5095d8",
    borderChecked: "0.5px solid #5095d8",
    boxShadowFocus: "0 0 0 0.5px #5095d8",
  },
  Divider: {
    color: "#EFEFF53D",
  },
  Input: {
    color: "#212121FF",
    colorFocus: "#212121FF",
    colorFocusError: "#212121FF",
    textColor: "#BBBBBBFF",
    borderHover: "1px solid #5095d8",
    borderFocus: "1px solid #5095d8",
    border: "1px solid #2F2F30FF",
    boxShadowFocus: "0 0 1px #5095d8",
    caretColor: "#BBBBBBFF",
  },
  Pagination: {
    buttonIconColor: "#BBBBBBFF",
    buttonIconColorHover: "#5095d8",
    buttonBorderHover: "1px solid #5095d8",
    itemTextColor: "#BBBBBBFF",
    itemTextColorActive: "#5095d8",
    itemTextColorHover: "#5095d8",
    itemBorderActive: "1px solid #5095d8",
    color: "#212121FF",
  },
  common: {
    textColor1: "#BBBBBBFF",
    inputColor: "#212121FF",
    primaryColor: "#5095d8",
  },
  Spin: {
    color: "rgb(255, 255, 255)",
  },
  Tabs: {
    tabTextColorHoverBar: "#5095d8",
    tabTextColorBar: "#BBBBBBFF",
    tabTextColorActiveBar: "#5095d8",
    barColor: "#5095d8",
  },
  Select: {
    peers: {
      InternalSelection: {
        textColor: "#BBBBBBFF",
      },
    },
  },
  Drawer: {
    color: "#363636FF",
    textColor: "#BBBBBBFF",
    titleTextColor: "#BBBBBBFF",
  },
};
