<template>
  <div class="unitRequestForm">
    <n-card
      title="New Unit/Squadron Request Form"
      size="small"
      style="margin-bottom: 1rem"
    >
      In order to request the creation of a new Unit/Squadron please provide the
      below information. Once the information has been reviewed, a system
      administrator will process the request.
    </n-card>
    <n-form :model="form" :rules="rules" ref="formRef">
      <n-form-item label="POC Name" path="pocName">
        <n-input
          v-model:value="form.pocName"
          placeholder="Point of contact name"
          :disabled="submitting"
        />
      </n-form-item>
      <n-form-item label="POC Email" path="pocEmail">
        <n-input
          v-model:value="form.pocEmail"
          placeholder="Point of contact email"
          :disabled="submitting"
        />
      </n-form-item>
      <n-form-item label="POC Work Phone" path="pocPhone">
        <n-input
          v-model:value="form.pocPhone"
          placeholder="Point of work phone"
          :disabled="submitting"
        />
      </n-form-item>
      <n-form-item label="Unit" path="unit">
        <n-input
          v-model:value="form.unit"
          type="number"
          placeholder="Requesting unit/squadron number (102)"
          :disabled="submitting"
        />
      </n-form-item>
      <n-form-item label="Unit Type" path="type">
        <n-input
          v-model:value="form.type"
          placeholder="Unit/Squadron type (medical, intel, comm, etc..)"
          :disabled="submitting"
        />
      </n-form-item>
      <n-form-item label="Unit Level" path="level">
        <n-select
          v-model:value="form.level"
          :options="unitLevelOptions"
          :disabled="submitting"
        />
      </n-form-item>
      <n-form-item label="Base/Location" path="base">
        <n-input
          v-model:value="form.base"
          placeholder="Unit base/location"
          :disabled="submitting"
        />
      </n-form-item>
      <n-form-item label="Unit Commander Rank/Name" path="cc">
        <n-input
          v-model:value="form.cc"
          placeholder="Commander full name"
          :disabled="submitting"
        />
      </n-form-item>
    </n-form>
    <div align="center">
      <n-button
        @click="handleValidateClick"
        size="large"
        :disabled="submitting"
      >
        Submit
      </n-button>
    </div>
  </div>
</template>

<script setup>
import { useKeycloak } from "@baloise/vue-keycloak";
import { NForm, NFormItem, NInput, NSelect, NButton, NCard } from "naive-ui";
import { ref } from "vue";
import { useUnitStore } from "@/store/unitStore";
import api from "@/api";
import { useMessage } from "naive-ui";
const units = useUnitStore();
const message = useMessage();
const { decodedToken } = useKeycloak();
const form = ref({
  pocName: "",
  pocEmail: "",
  pocPhone: "",
  unit: "",
  type: "",
  level: "",
  base: "",
  cc: "",
  createdBy: decodedToken.value.preferred_username,
  approved: false,
});
const submitting = ref(false);
const formRef = ref(null);
const handleValidateClick = () => {
  formRef.value.validate(async (err) => {
    if (!err) {
      try {
        submitting.value = true;
        const resp = await api.postUnit(form.value);
        if (resp.status === 200) {
          message.success(
            "Request sent! Admins will review the request and contact the POC."
          );
          form.value.pocName = "";
          form.value.pocEmail = "";
          form.value.pocPhone = "";
          form.value.unit = "";
          form.value.type = "";
          form.value.level = "";
          form.value.base = "";
          form.value.cc = "";
        } else {
          throw new Error("Error sending request..");
        }
      } catch (error) {
        console.error(error);
        message.error(error.message);
      }
      submitting.value = false;
    }
  });
};
const rules = {
  pocName: {
    required: true,
    message: "Please the POC name",
  },
  pocEmail: {
    required: true,
    validator: (rule, value) => {
      if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value)) {
        return Error("Please provide a valid email address");
      }
      return true;
    },
    trigger: "blur",
  },
  unit: {
    required: true,
    validator: (rule, value) => {
      if (units.allUnitNumbers.includes(value.toString())) {
        return Error("Unit already requested.");
      }
      if (!value) {
        return Error("Please provide a unit number");
      }
      return true;
    },
    trigger: ["blur", "input"],
  },
  base: {
    required: true,
    message: "Please provide the base/location",
  },
  type: {
    required: true,
    message: "Please provide a unit type. (e.g. intel, comm, mx)",
  },
  level: {
    required: true,
    message: "Please provide the new unit level (Sqaudron, Group, Wing)",
  },
  pocPhone: {
    required: true,
    message: "Please provide your work phone number",
  },
  cc: {
    required: true,
    message: "Please provide unit commander's rank and name.",
  },
};

const unitLevelOptions = [
  {
    label: "Select Unit level (Squadron, Group, Wing)",
    value: "",
    disabled: true,
  },
  { label: "Squadron", value: "Squadron" },
  { label: "Group", value: "Group" },
  { label: "Wing", value: "Wing" },
];
</script>

<style scoped>
.unitRequestForm {
  margin: 0 auto;
  width: 500px;
  max-width: 90vw;
  text-align: left;
}
</style>
