<template>
  <!-- <n-theme-editor> -->
  <n-config-provider :theme-overrides="themeOverrides" :theme="darkTheme">
    <n-message-provider :duration="6000" keep-alive-on-hover>
      <h1 align="center" class="loading" v-if="!isAuthenticated">
        Spawning your credentials...
        <br />
        <n-spin size="large" />
      </h1>
      <div
        v-else-if="
          isAuthenticated &&
          sponsors.userStatus &&
          sponsors.userStatus !== 'pending'
        "
      >
        <nav class="flex">
          <div class="flex" v-if="isAuthenticated">
            <n-button :focusable="false" @click="keycloak.logout()">
              Logout
            </n-button>
            <n-tooltip trigger="hover">
              <template #trigger>
                <n-h3 class="username">
                  <a
                    href="https://auth.devilops.dso.mil/auth/realms/dgs4/account/"
                    target="_blank"
                  >
                    {{ auth.displayName }}
                  </a>
                  <span v-if="auth.isSiteAdmin"> (Site Admin)</span>
                  <span v-else-if="auth.isAdmin"> (Admin)</span>
                  <span v-else-if="auth.isSponsor"> (Sponsor)</span>
                </n-h3>
              </template>
              <span>View profile</span>
            </n-tooltip>
          </div>
          <div class="nagivation">
            <template v-for="route in routes">
              <router-link
                :to="route.path"
                :key="route.path"
                v-if="
                  route.name !== '' &&
                  (!route.secured || route.secured === auth.isAdmin) &&
                  route?.show !== false
                "
                class="link"
              >
                <n-button text :focusable="false">{{ route.name }}</n-button>
              </router-link>
            </template>
          </div>
        </nav>
        <n-scrollbar style="max-height: calc(100vh - 75px)">
          <h1
            align="center"
            class="loading"
            v-if="!auth.isAdmin && !auth.isSponsor"
          >
            No Unit Data Available Yet...
          </h1>
          <router-view v-else-if="!govBannerShow" />
        </n-scrollbar>
      </div>
      <div v-else>
        <h1 class="header">SPAWNER</h1>
        <h4 align="center">
          Thank you for using SPAWNER, it looks like your request to join a unit
          is still <u>pending</u>.<br />Until your request is <u>approved</u> by
          an admin in the unit you cannot access this site.
        </h4>
        <n-h2>
          <n-text type="primary"> Update your unit access request</n-text>
        </n-h2>
        <unit-transfer />
        <div style="margin: 3rem 0" v-if="development">
          <n-button @click="sponsors.userStatus = 'approved'">
            DEV POWER ACTIVATE!
          </n-button>
        </div>
      </div>
      <n-modal
        @close-modal="govBannerShow = !govBannerShow"
        v-model:show="govBannerShow"
        v-if="isAuthenticated"
        :mask-closable="false"
        @is-authenticated="changeAuth"
        style="margin-top: 6rem"
      >
        <gov-banner />
      </n-modal>
      <n-badge
        class="admin-button"
        :value="units.pendingRequests"
        v-if="auth.isSiteAdmin"
      >
        <n-button
          :focusable="false"
          @click="router.push({ path: '/admin' })"
          circle
        >
          <template #icon>
            <n-icon>
              <Key />
            </n-icon>
          </template>
        </n-button>
      </n-badge>
    </n-message-provider>
  </n-config-provider>
  <!-- </n-theme-editor> -->
</template>

<script setup>
import { useAuthStore } from "./store/authStore";
import { Key } from "@vicons/fa";
import { routes } from "./router";
import router from "./router";
import GovBanner from "./components/GovBanner.vue";
import UnitTransfer from "@/components/UnitTransfer";
import {
  NConfigProvider,
  NThemeEditor,
  NButton,
  NIcon,
  NSpin,
  NH3,
  NH2,
  NText,
  NScrollbar,
  NTooltip,
  NBadge,
  NModal,
  NMessageProvider,
  darkTheme,
} from "naive-ui";
import Themes from "@/assets/themes";
import { useSponsorStore } from "@/store/sponsorshipsStore";
import { useUnitStore } from "@/store/unitStore";
import { useKeycloak } from "@baloise/vue-keycloak";
import { watch } from "vue";
import { ref } from "vue";

const development = process.env.VUE_APP_ENV === "development";

const { isAuthenticated, isPending, decodedToken, keycloak } = useKeycloak();
const auth = useAuthStore();
const sponsors = useSponsorStore();
const units = useUnitStore();
const clientAuth = ref(false);
const changeAuth = (value) => {
  clientAuth.value = value;
};

const govBannerShow = ref(true);
watch(isAuthenticated, (value) => {
  if (value) {
    const info = decodedToken.value;
    auth.setAuth(
      `${info.given_name} ${info.family_name}`,
      info.email,
      info.resource_access?.spawner?.roles || [],
      keycloak.token
    );
  } else if (!isPending && !value) {
    keycloak.logout();
  }
});

watch(clientAuth, (value) => {
  if (isAuthenticated.value && value) {
    sponsors.getSponsorData();
    sponsors.getData(15);
    units.getUnits();
    units.getChecklist();
  }
});

const themeOverrides = Themes;

watch(router.currentRoute, (value) => {
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].path === value.path) {
      if (routes[i].secured) {
        if (!auth.isAdmin) {
          router.push("/403");
        }
      }
    }
  }
});
</script>

<style scoped>
.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}
.nagivation {
  padding: 10px 15px;
  column-gap: 1.5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.username {
  padding: 0;
  margin: 0 0 0 2rem;
}
.username a {
  color: rgb(224, 224, 230);
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}
.username a:hover {
  color: #5095d8;
  text-decoration: none;
}
.loading {
  color: white;
  mix-blend-mode: difference;
  margin-top: 25vh;
}
.admin-button {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}
.admin-button button {
  height: 3rem;
  width: 3rem;
}
.n-scrollbar-container {
  margin: 0 auto;
}
</style>

<style>
@font-face {
  font-family: "roboto";
  src: url("./assets/fonts/Roboto-Regular.ttf");
}
html,
body {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: rgb(224, 224, 230);
}
body {
  background-color: #22272e;
}
.link {
  text-decoration: none;
}
.router-link-active {
  border-bottom: 1px solid white;
}
.header {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  letter-spacing: 8px;
}
.modalCloseBtn {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
</style>
