import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { vueKeycloak } from "@baloise/vue-keycloak";
import { createPinia } from "pinia";
import VueApexCharts from "vue3-apexcharts";
import piniaPersist from "pinia-plugin-persist";
const pinia = createPinia();
pinia.use(piniaPersist);

const keycloakConfig = {
  initOptions: {
    flow: "standard",
    checkLoginIframe: false,
    onLoad: "login-required",
  },
  config: {
    url: "https://auth.devilops.dso.mil/auth",
    realm: process.env.VUE_APP_ENV === "development" ? "dev" : "dgs4",
    clientId: "spawner",
  },
};

createApp(App)
  .use(router)
  .use(vueKeycloak, keycloakConfig)
  .use(pinia)
  .use(VueApexCharts)
  .mount("#app");
