// // function parseDate(date) {
// //   const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
// //   "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
// // ];
// //   return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
// // }

// const today = () => {
//   const date = new Date();

// const month = (date.getMonth() + 1).toString().padStart(2, '0');
// const day = date.getDate().toString().padStart(2, '0');
// const year = date.getFullYear();

// const formattedDate = `${month}/${day}/${year}`;

// return formattedDate;
// }

// const format = (text) => {
// const date = new Date(text);

// const month = (date.getMonth() + 1).toString().padStart(2, '0');
// const day = date.getDate().toString().padStart(2, '0');
// const year = date.getFullYear();

// const formattedDate = `${month}/${day}/${year}`;

// return formattedDate;
// }

// const randomUpdates =() => {
//   const updates = ["Just received orders for my PCS from [unit name] to [new location].",
//   "Started packing up my belongings for the move.",
//   "Turned in my housing paperwork and started looking for a place to live at my new duty station.",
//   "Made travel arrangements for my family and me to get to our new location.",
//   "Attending pre-move briefings to prepare for the PCS process.",
//   "Completed a home inventory and turned it in to the transportation office.",
//   "Started saying goodbye to friends and colleagues at [unit name].",
//   "Participated in a 'hail and farewell' event to honor outgoing and incoming members of the unit.",
//   "Turned in my equipment and turned over any duties or responsibilities to my replacement.",
//   "Attended a farewell ceremony for my unit.",
//   "Packed up my household goods and shipped them to my new duty station.",
//   "Drove my personally owned vehicle (POV) to my new location.",
//   "Arrived at my new duty station and checked into temporary lodging.",
//   "Met with my new unit's leadership and received an orientation to the area.",
//   "Started unpacking and settling into my new home.",
//   "Enrolled my family in the local school district and made arrangements for childcare.",
//   "Located the nearest grocery store, gas station, and other amenities.",
//   "Toured my new duty station and learned about the local area.",
//   "Started exploring recreational and leisure activities in the area.",
//   "Completed my in-processing and officially checked into my new unit."]
//   updates.sort(() => Math.random() - 0.5);
//   return updates

// }

// const testData = [{"id":1,"memberName":"Sigvard Flade","unit":"8 IS","date":"11/17/2022","afsc":"1N1","rank":"AB","lastUpdate":"1/2/2023","sponsorshipDate":"11/27/2022","RNLTD":"10/11/2023", "sponsorName": "William Getman"},
// {"id":2,"memberName":"Cher McCluney","unit":"11 IS","date":"4/5/2022","afsc":"1N1","rank":"TSgt","lastUpdate":"1/2/2023","sponsorshipDate":"11/30/2022","RNLTD":"9/11/2023", "sponsorName": "William Getman"},
// {"id":3,"memberName":"Kingsly Limon","unit":"315 TRS","date":"12/24/2021","afsc":"1N0","rank":"SrA","lastUpdate":today(),"sponsorshipDate":"11/19/2022","RNLTD":"10/18/2023", "sponsorName": "Mathieu Sherburne"},
// {"id":4,"memberName":"Reamonn Beecraft","unit":"692 ISRG","date":"3/11/2022","afsc":"1N2","rank":"SrA","lastUpdate":"12/23/2022","sponsorshipDate":"12/11/2022","RNLTD":"9/30/2023", "sponsorName": "William Getman"},
// {"id":5,"memberName":"Danella Whines","unit":"316 TRS","date":"3/26/2022","afsc":"1N4","rank":"A1C","lastUpdate":"12/28/2022","sponsorshipDate":"11/23/2022","RNLTD":"7/28/2023", "sponsorName": "Mathieu Sherburne"},
// {"id":6,"memberName":"Toby Loveard","unit":"8 IS","date":"8/17/2022","afsc":"1N2","rank":"A1C","lastUpdate":"12/18/2022","sponsorshipDate":"12/11/2022","RNLTD":"9/3/2023", "sponsorName": "William Getman"},
// {"id":7,"memberName":"Linus Hull","unit":"692 ISRG","date":"8/23/2022","afsc":"1N4","rank":"SrA","lastUpdate":"1/7/2023","sponsorshipDate":"11/26/2022","RNLTD":"9/15/2023", "sponsorName": "William Getman"},
// {"id":8,"memberName":"Danni Mattityahou","unit":"316 TRS","date":"6/30/2022","afsc":"1N4","rank":"TSgt","lastUpdate":"12/18/2022","sponsorshipDate":"12/13/2022","RNLTD":"8/9/2023", "sponsorName": "Mathieu Sherburne"},
// {"id":9,"memberName":"Lucretia Andrey","unit":"11 IS","date":"7/24/2022","afsc":"1N4","rank":"AB","lastUpdate":"12/21/2022","sponsorshipDate":"11/30/2022","RNLTD":"9/27/2023", "sponsorName": "William Getman"},
// {"id":10,"memberName":"Basilio De Simone","unit":"8 IS","date":"6/12/2022","afsc":"1N4","rank":"SSgt","lastUpdate":"12/19/2022","sponsorshipDate":"12/3/2022","RNLTD":"10/6/2023", "sponsorName": "William Getman"},
// {"id":11,"memberName":"Ulla Darey","unit":"315 TRS","date":"8/31/2022","afsc":"1N2","rank":"SSgt","lastUpdate":"12/23/2022","sponsorshipDate":"12/1/2022","RNLTD":"5/6/2023", "sponsorName": "William Getman"},
// {"id":12,"memberName":"Myrlene Garrould","unit":"316 TRS","date":"10/30/2022","afsc":"1N1","rank":"A1C","lastUpdate":"12/19/2022","sponsorshipDate":"11/14/2022","RNLTD":"6/21/2023", "sponsorName": "William Getman"}]

// for (let i = 0; i < testData.length; i++) {
//   const unit = testData[i].unit.split(" ")[0]
//   if (unit === "9" || unit === "13"){
//     testData[i].base = "Beale AFB"
//   }
//   else if (unit === "8" || unit === "692") {
//     testData[i].base = "Hickam AFB"
//   }
//   else if (unit === "315" || unit === "316") {
//     testData[i].base = "Goodfellow AFB"
//   }
//   else if (unit === "11") {
//     testData[i].base = "Hurlbert Field AFB"
//   }
//   const updates = 
//   testData[i].updates = [{date: testData[i].lastUpdate, updateText: randomUpdates()[i]}, {date: format(new Date().setDate(new Date(testData[i].lastUpdate).getDate() - 7)), updateText: randomUpdates()[i]}]
// }
// const dataGenerator = require("./dataGenerator.js")
// const testData = dataGenerator.getData(12)

// export { testData }

// function that turns date into a string mm/dd/yyyy
function formatDate(date) {
  const d = new Date(date)
  const month = `${d.getMonth() + 1}`
  const day = `${d.getDate()}`
  const year = d.getFullYear()
  return [month, day, year].join("/")
}




export const columns = [
  {
    type: "selection",
  },
  {
    title: "Member Name",
    key: "memberName",
    defaultSortOrder: false,
    sorter: "default"
  },
  {
    title: "Sponsor Name",
    key: "sponsorName",
    sorter: "default"
  },
  {
    title: "Unit",
    key: "oldUnit",
    sorter(row1, row2) {
      const numberExtractor = (text) => {
        const number = text.match(/\d+/)
        return number
      }
      return numberExtractor(row1.unit) - numberExtractor(row2.unit)
    }
  },
  {
    title: "AFSC",
    key: "afsc",
    sorter: "default"
  },
  {
    title: "Last Updated",
    key: "lastModifiedAt",
    className: "lastUpdate",
    sorter(row1, row2) {
      return new Date(row1.lastUpdate) - new Date(row2.lastUpdate)
    },
    render: (row, index) => {
      return formatDate(row.lastModifiedAt)
      // render: (row, index) => {
      //   return parseDate(row.lastUpdate)
      // }
    },
  },
  {
    title: "Sponsorship Date",
    key: "createdAt",
    sorter: "default",
    render: (row, index) => {
      return formatDate(row.createdAt)
    }
    // render: (row, index) => {
    //   return parseDate(row.sponsorshipDate)
    // }
  },
  {
    title: "RNLTD",
    key: "RNLTD",
    sorter: "default"
    // render: (row, index) => {
    //   return parseDate(row.RNLTD)
    // }
  },
  {
    title: "Rank",
    key: "rank",
    sorter(row1, row2) {
      const ranks = ["AB", "A1C", "SrA", "SSgt", "TSgt", "MSgt", "SMSgt", "CMSgt", "2Lt", "1Lt", "Capt", "Maj", "Lt Col", "Col", "Brig Gen", "Maj Gen", "Lt Gen", "Gen"]
      return ranks.indexOf(row1.rank) - ranks.indexOf(row2.rank)
    }
  },
]

export const sponsorTableColumns = [
  {
    title: "Name",
    key: "name",
    defaultSortOrder: false,
    sorter: "default"
  },
  {
    title: "AFSC",
    key: "afsc",
    sorter: "default"
  },
  {
    title: "Rank",
    key: "rank",
    sorter(row1, row2) {
      const ranks = ["AB", "A1C", "SrA", "SSgt", "TSgt", "MSgt", "SMSgt", "CMSgt", "2Lt", "1Lt", "Capt", "Maj", "Lt Col", "Col", "Brig Gen", "Maj Gen", "Lt Gen", "Gen"]
      return ranks.indexOf(row1.rank) - ranks.indexOf(row2.rank)
    }
  },
  {
    title: "Flight",
    key: "flight",
    sorter: "default"
  },

]
export const sponsorData = [
  {
    name: "William Getman",
    afsc: "1N4",
    rank: "SSgt",
    flight: "DOA"
  },
  {
    name: "Mathieu Sherburne",
    afsc: "1N1",
    rank: "SrA",
    flight: "DOS"
  },
]
