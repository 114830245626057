<template>
  <div class="card">
    <n-card :title="data.memberName">
      <template #header-extra>
        <!-- 1. Create a tooltip that displays the percentage of the days until arrival -->
        <n-tooltip v-if="percentage < 100">
          <template #trigger>
            <n-progress style="width: 3rem; margin-right: 2rem" type="circle" :percentage="percentage" />
          </template>
          <span>{{ days.RNLDate }} days until arrival</span>
        </n-tooltip>
        <n-button type="text" @click="openDrawer = !openDrawer" :disabled="!editable" v-else>
          <n-icon size="30">
            <OutputFilled />
          </n-icon>
        </n-button>
        <!-- 2. Create a tooltip that displays the "Open details" message if the days since the last update are greater than six -->
        <n-tooltip>
          <template #trigger>
            <n-button text @click="showSponsorshipModal()" :focusable="false">
              <n-icon :component="MenuFilled" size="30" />
            </n-button>
          </template>
          <span>Open details</span>
        </n-tooltip>
      </template>
      <template #footer>
        <!-- prettier-ignore -->
        <h4 v-if="percentage < 100" style="padding-top: 0; margin-top: 0; display: flex; align-items: center;">
          {{
            days.daysSinceUpdate > 6
            ? `Last updated: ${days.daysSinceUpdate} days ago`
            : "Up to date"
          }}
          <n-tooltip v-if="days.daysSinceUpdate > 6">
            <template #trigger>
              <n-icon size="15" :color="days.daysSinceUpdate > 6 ? '#7E1204' : 'green'" :component="CircleFilled"
                style="align-items: center; margin-left: 10px" />
            </template>
            <span>No updates in the last {{ days.daysSinceUpdate }} days.</span>
          </n-tooltip>
        </h4>
        <h4 v-else style="color: green">ARRIVED</h4>
        <div class="flex">
          <div class="buttons" v-if="props.editable">
            <n-tooltip>
              <template #trigger>
                <n-button text :focusable="false" @click="showUpdateModal">
                  <template #icon>
                    <n-icon :component="PlusFilled" size="30" />
                  </template>
                </n-button>
              </template>
              <span>Add update</span>
            </n-tooltip>
          </div>
          <p style="font-size: 10px">{{ props.data.sponsorName }}</p>
        </div>
      </template>
      <div class="info">
        <p>AFSC: {{ props.data.afsc }}</p>
        <p>Unit: {{ props.data.oldUnit }}</p>
        <p>
          Last Updated: {{ new Date(lastUpdatedAuthoritive).toLocaleString() }}
        </p>
        <p>Flight: {{ props.data?.flight ? props.data.flight : "Unknown" }}</p>
      </div>
    </n-card>

    <n-modal v-model:show="sponsorshipModal">
      <div style="width: 800px; max-width: 80vw; height: 80vh">
        <n-card :title="`${data.memberName}, ${props.data.memberRank}`">
          <div style="display: flex; justify-content: space-between">
            <div>
              <p><strong>Sponsor:</strong> {{ props.data.sponsorName }}</p>
              <p><strong>AFSC:</strong> {{ props.data.afsc }}</p>
              <p>
                <strong>Housing:</strong>
                {{
                  props.data.projectedHousing
                  ? props.data.projectedHousing
                  : "Not Identified"
                }}
              </p>
              <p><strong>Losing Unit:</strong> {{ props.data.oldUnit }}</p>
              <p><strong>RNLTD:</strong> {{ formatDate(props.data.rnltd) }}</p>
              <p>
                <strong>Sponsorship Started:</strong>
                {{ formatDate(props.data.createdAt) }}
              </p>
              <p v-if="percentage < 100" style="display: flex; align-items: center">
                <span>
                  <strong>Last Updated:</strong>
                  {{ formatDate(lastUpdatedAuthoritive) }}
                </span>
                <n-tooltip v-if="days.daysSinceUpdate > 6">
                  <template #trigger>
                    <n-icon size="20" :color="days.daysSinceUpdate > 6 ? '#7E1204' : 'green'" :component="CircleFilled"
                      style="align-items: center; margin-left: 10px" />
                  </template>
                  <span>No updates in the last
                    {{ days.daysSinceUpdate }} days.</span>
                </n-tooltip>
              </p>
              <p v-else style="color: green">ARRIVED</p>
              <h2>Updates:</h2>
            </div>
            <div>
              <n-progress type="circle" :percentage="percentage > 100 ? 100 : percentage" />
            </div>
          </div>
          <div>
            <n-scrollbar style="height: 400px">
              <div v-if="props.data.comments">
                <n-card v-for="update in props.data.comments" :key="update.update" :title="formatDate(update.createdAt)"
                  size="small">
                  <div>
                    <h4 style="padding-top: 0; margin-top: 0">
                      {{ update.update }}
                    </h4>
                    <p style="font-size: 10px">{{ update.createdBy }}</p>
                  </div>
                </n-card>
              </div>
              <h3 v-else>No Updates Yet</h3>
            </n-scrollbar>
          </div>
        </n-card>
      </div>
    </n-modal>

    <n-modal v-model:show="updateModal">
      <update-card @close="closeModal" :data="props.data" />
    </n-modal>
    <NDrawer width="30rem" style="padding: 20px" v-model:show="openDrawer">
      <ChecklistDrawer :id="data.id" :data="data" @close="openDrawer = !openDrawer" />
    </NDrawer>
  </div>
</template>

<script setup>
// import components
import UpdateCard from "@/components/UpdateCard.vue";
import { ref, computed } from "vue";
import {
  NModal,
  NDrawer,
  NCard,
  NButton,
  NIcon,
  NForm,
  NFormItem,
  NInput,
  NProgress,
  NScrollbar,
  NTooltip,
} from "naive-ui";

// import icons
import {
  CircleFilled,
  PlusFilled,
  MenuFilled,
  OutputFilled,
} from "@vicons/material";
// import store
import { useSponsorStore } from "@/store/sponsorshipsStore";
import { useKeycloak } from "@baloise/vue-keycloak";
import api from "@/api";
import ChecklistDrawer from "@/components/ChecklistDrawer.vue";
const openDrawer = ref(false);
const sponsors = useSponsorStore();
const lastUpdatedAuthoritive = computed(() => {
  if (props.data?.comments && props.data.comments.length > 0) {
    const comments = props.data.comments.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    return comments[0].createdAt;
  } else {
    return props.data.lastModifiedAt;
  }
});
// function that turns Date into format MM/DD/YYYY
const formatDate = (date) => {
  const d = new Date(date);
  const month = `${d.getMonth() + 1}`.padStart(2, "0");
  const day = `${d.getDate()}`.padStart(2, "0");
  const year = d.getFullYear();
  const output = [month, day, year].join("/");
  return output;
};
// define props
const props = defineProps({
  data: Object,
  editable: Boolean,
});

if (props.data.comments) {
  props.data.comments.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
}
const closeModal = () => {
  updateModal.value = false;
  sponsors.getSponsorData();
};
// define refs

const sponsorshipModal = ref(false);
const updateModal = ref(false);

const showUpdateModal = () => {
  updateModal.value = true;
};

const showSponsorshipModal = () => {
  sponsorshipModal.value = true;
};

const daysBetween = (date1, date2) => {
  // The number of milliseconds in one day
  const ONE_DAY = 1000 * 60 * 60 * 24;

  const date1_ms = date1.getTime();
  const date2_ms = date2.getTime();

  // Calculate the difference in milliseconds
  const differenceMs = Math.abs(date1_ms - date2_ms);

  // Convert back to days and return
  return Math.ceil(differenceMs / ONE_DAY);
};

const days = {
  daysSinceUpdate: daysBetween(
    new Date(),
    new Date(lastUpdatedAuthoritive.value)
  ),
  totalDays: daysBetween(
    new Date(props.data.createdAt),
    new Date(props.data.rnltd)
  ),
  refDate: daysBetween(new Date(props.data.createdAt), new Date()),
  RNLDate: daysBetween(new Date(props.data.rnltd), new Date()),
};
console.log(lastUpdatedAuthoritive.value);

const percentage = ref(Math.floor((days.refDate / days.totalDays) * 100));
// if (isNaN(percentage.value)) {
//   percentage.value = 100;
// }
</script>

<style scoped>
.card {
  width: calc(25% - 1rem);
  min-width: 300px;
  height: 300px;
  display: flex;
  flex-direction: row;
  text-align: left;
}

.info p {
  margin: 0;
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

:deep(.n-card-header .n-progress-text) {
  font-size: 1rem;
}
</style>
