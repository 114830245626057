<template>
  <n-card style="width: 80vw; max-height: 90vh">
    <h2
      align="center"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
      "
    >
      <div>Import GAINS Roster</div>
      <div>
        <n-tooltip trigger="hover"
          ><template #trigger><n-icon :component="HelpOutlined" /></template>A
          GAINS Roster with the minimum fields required is used. PII is stripped
          by only pulling fields that are used by the application.</n-tooltip
        >
      </div>
    </h2>
    <div align="center" style="margin: 1rem 0">
      <n-upload
        :custom-request="customRequest"
        :on-remove="onDelete"
        accept=".xlsx"
        style="width: 400px"
      >
        <n-button :focusable="false">
          <template #icon>
            <n-icon :component="UploadFileFilled" />
          </template>
          Select a file to upload
        </n-button>
        <p class="error" v-if="error">
          Error{{ message ? ": " + message : "" }}
        </p>
      </n-upload>
    </div>
    <n-scrollbar y-scrollable style="max-height: calc(90vh - 17rem)">
      <n-space justify="center" size="medium">
        <n-spin v-if="loading" />
        <n-data-table
          :columns="headers"
          :data="rows"
          :max-height="500"
          style="min-width: 400px"
          v-else
        ></n-data-table>
      </n-space>
    </n-scrollbar>
    <div align="center" style="margin: 1rem 0" v-if="headers[0]">
      <n-button @click="onSubmit" :focusable="false">
        <template #icon>
          <n-icon :component="UploadOutlined" />
        </template>
        Upload Sponsors
      </n-button>
    </div>
  </n-card>
</template>

<script setup>
// import NaiveUI Button, Card, and Upload
import { ref, watch } from "vue";
import { csvJSON } from "@/api/uploadCSV";
import api from "@/api";
import {
  NButton,
  NCard,
  NUpload,
  NIcon,
  NScrollbar,
  NTooltip,
  NInput,
  NDataTable,
  NSpin,
  NSpace,
  NTabs,
  NTabPane,
} from "naive-ui";
import {
  UploadFileFilled,
  UploadOutlined,
  HelpOutlined,
} from "@vicons/material";
import { useSponsorStore } from "@/store/sponsorshipsStore";
const error = ref(false);
const sponsors = useSponsorStore();
const headers = ref([]);
const rows = ref([]);
const fileRef = ref(null);
const loading = ref(false);
const customRequest = async ({ file, data }) => {
  loading.value = true;
  const text = await file.file.arrayBuffer();
  const { theHeaders, theRows, Err } = csvJSON(text);
  if (Err) {
    error.value = true;
    message.value = Err;
    loading.value = false;
    return;
  } else {
    error.value = false;
    message.value = "";
  }
  const sponsorColumns = [
    {
      title: "SPONSOR NAME",
      key: "sponsor_name",
      render(row) {
        return <NInput v-model:value={row.sponsor_name} />;
      },
    },
    {
      title: "SPONSOR EMAIL",
      key: "sponsor_email",
      render(row) {
        return <NInput v-model:value={row.sponsor_email} />;
      },
    },
  ];
  loading.value = false;
  headers.value = [...theHeaders, ...sponsorColumns];
  rows.value = theRows.map((row) => {
    return {
      ...row,
      sponsor_name: "Mathieu Sherburne",
      sponsor_email: "mathieu.sherburne@us.af.mil",
    };
  });
};

const emit = defineEmits(["close-upload"]);

const onSubmit = async () => {
  const data = rows.value.map((row) => {
    return {
      sponsorName: row.sponsor_name,
      sponsorEmail: row.sponsor_email,
      afsc: row.DAFSC,
      memberRank: row["GRADE"],
      memberName: row["FULL NAME"],
      rnltd: row.RNLTD,
      oldUnit: row["LOSING UNIT"],
      newUnit: sponsors.unit,
    };
  });
  await api.postBulkSponsorship(data);
  sponsors.getSponsorData();
  emit("close-upload");
};

const onDelete = (file) => {
  headers.value = null;
  fileRef.value = null;
};

const message = ref("");
</script>

<style scoped>
.error {
  color: red;
}
</style>
