<template>
  <div>
    <n-card class="bannerCard">
      <div>
        <p>
          You are accessing a U.S. Government (USG) Information System (IS) that
          is provided for USG-authorized use only.
        </p>
        <p>
          By using this IS (which includes any device attached to this IS), you
          consent to the following conditions:
        </p>
        <ul>
          <li>
            The USG routinely intercepts and monitors communications on this IS
            for purposes including, but not limited to, penetration testing,
            COMSEC monitoring, network operations and defense, personnel
            misconduct (PM), law enforcement (LE), and counterintelligence (CI)
            investigations.
          </li>
          <li>
            At any time, the USG may inspect and seize data stored on this IS.
          </li>
          <li>
            Communications using, or data stored on, this IS are not private,
            are subject to routine monitoring, interception, and search, and may
            be disclosed or used for any USG authorized purpose.
          </li>
        </ul>
        <p>
          This IS includes security measures (e.g., authentication and access
          controls) to protect USG interests--not for your personal benefit or
          privacy.
        </p>
        <p>
          NOTICE: There is the potential that information presented and exported
          from the Air Force Research Laboratory contains For Official Use Only
          (FOUO) or Controlled Unclassified Information (CUI). It is the
          responsibility of all users to ensure information extracted from the
          Force Development Road Map Training Management System is appropriately
          marked and properly safeguarded. If you are not sure of the safeguards
          necessary for the information, contact your functional lead or
          Information Security Officer.
        </p>
        <p>
          Notwithstanding the above, using this IS does not constitute consent
          to PM, LE or CI investigative searching or monitoring of the content
          of privileged communications, or work product, related to personal
          representation or services by attorneys, psychotherapists, or clergy,
          and their assistants. Such communications and work product are private
          and confidential. See User Agreement for details.
        </p>
        <div class="bannerButtons">
          <n-button
            @click="onButtonClick"
            :disabled="!appReady"
            :focusable="false"
            size="large"
          >
            {{ buttonText }}
          </n-button>
          <div v-if="requestUnitButton">
            <n-divider style="margin: 5px 0">OR</n-divider>
            <n-button
              @click="showUnitRequestModal = true"
              :focusable="false"
              size="large"
            >
              Request New Unit
            </n-button>
          </div>
        </div>
      </div>
    </n-card>
    <n-modal v-model:show="showFinishProfile">
      <n-card style="width: 500px">
        <n-tooltip>
          <template #trigger>
            <n-button
              circle
              quaternary
              @click="showFinishProfile = false"
              class="modalCloseBtn"
            >
              <template #icon>
                <n-icon :component="CloseFilled" />
              </template>
            </n-button>
          </template>
          <span>Close</span>
        </n-tooltip>
        <div>
          <h2 align="center" style="margin-top: 0">Complete your profile</h2>
          <span style="color: red">*</span> Select a unit to join
          <n-select
            v-model:value="unitChoice"
            :options="options"
            style="margin-bottom: 1rem"
            required
          />
          Provide justification to join the unit. (optional)
          <n-input
            v-model:value="unitJustification"
            type="textarea"
            placeholder="Justification (optional)"
          />
          <div style="text-align: center; padding-top: 2rem">
            <n-button @click="submitFinishProfile">Submit</n-button>
          </div>
        </div>
      </n-card>
    </n-modal>
    <n-modal v-model:show="showUnitRequestModal">
      <n-card style="width: 800px">
        <n-tooltip>
          <template #trigger>
            <n-button
              circle
              quaternary
              @click="showUnitRequestModal = false"
              class="modalCloseBtn"
            >
              <template #icon>
                <n-icon :component="CloseFilled" />
              </template>
            </n-button>
          </template>
          <span>Close</span>
        </n-tooltip>
        <unit-request />
      </n-card>
    </n-modal>
  </div>
</template>

<script setup>
import UnitRequest from "@/views/UnitRequest";
import { ref } from "vue";
import {
  NCard,
  NButton,
  NIcon,
  NModal,
  NSelect,
  NInput,
  NDivider,
  NTooltip,
} from "naive-ui";
import api from "@/api";
import { useKeycloak } from "@baloise/vue-keycloak";
import { useSponsorStore } from "@/store/sponsorshipsStore";
import { useUnitStore } from "@/store/unitStore";
import { CloseFilled } from "@vicons/material";
import { useMessage } from "naive-ui";
const message = useMessage();
const { decodedToken } = useKeycloak();
const emit = defineEmits(["closeModal", "isAuthenticated"]);
const appReady = ref(false);
const mode = ref("created");
const buttonText = ref("Loading...");
const options = ref([]);
const showFinishProfile = ref(false);
const showUnitRequestModal = ref(false);
const sponsors = useSponsorStore();
const Units = useUnitStore();
const requestUnitButton = ref(false);
const gatherUserProfile = async () => {
  // Insert call to get units here
  const allUnits = await api.getAllUnits();
  Units.allUnits = allUnits;
  // insert user profile call here
  const person = await api.getCurrentUser(decodedToken.value.email);
  if (person?.associatedUnit) {
    let userUnit = person.associatedUnit;
    userUnit = allUnits.filter((unit) => unit.id === userUnit)[0];
    sponsors.unit = userUnit?.unit;
    sponsors.unitId = userUnit?.id;
    sponsors.permissionGroupID = userUnit?.groupID;
    sponsors.userStatus = person.status;
    if (person.status === "approved") {
      appReady.value = true;
      buttonText.value = "Enter";
    } else if (person.status === "pending") {
      mode.value = "created";
      buttonText.value = "Enter";
      appReady.value = true;
    }
  } else {
    // Overwrite options with units
    options.value = Units.allUnits
      .filter(({ approved }) => approved)
      .map((unit) => ({
        label: `${unit.unit} - ${unit.type}`,
        value: unit.id,
      }));
    // Open modal
    mode.value = "create";
    buttonText.value = "Complete Your Profile";
    requestUnitButton.value = true;
    appReady.value = true;
  }
};
gatherUserProfile();

const onButtonClick = () => {
  if (mode.value === "create") {
    // insert call here
    showFinishProfile.value = true;
  } else if (mode.value === "created") {
    emit("isAuthenticated", true);
    emit("closeModal");
  }
};

const unitChoice = ref("");
const unitJustification = ref("");
const submitFinishProfile = async () => {
  // Insert call to finish profile here
  try {
    await api.updateUserProfile({
      email: decodedToken.value.email,
      associatedUnit: unitChoice.value,
      associatedUnitName: options.value.filter(
        (obj) => obj.value === unitChoice.value
      )[0].label,
      justification: unitJustification.value,
      status: "pending",
    });
    message.success("Profile updated.");
    showFinishProfile.value = false;
    emit("isAuthenticated", false);
    emit("closeModal");
  } catch (error) {
    console.error(error.message);
  }
};
</script>
<style scoped>
.bannerCard {
  width: 80vw;
}
.bannerButtons {
  margin: 3rem auto 0 auto;
  text-align: center;
  width: fit-content;
}
.bannerCard p:first-child {
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
}
.bannerCard p {
  font-size: 1.1rem;
}
.bannerCard li {
  font-size: 1.1rem;
}
</style>
