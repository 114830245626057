<template>
  <n-card style="width: 800px; max-width: 80vw">
    <h1>New Sponsorship</h1>
    <n-spin :show="processing">
      <n-form ref="formRef" :model="form" :rules="rules">
        <n-form-item label="Member Name" path="memberName">
          <n-input placeholder="Full Name" v-model:value="form.memberName" />
        </n-form-item>
        <n-form-item label="Member Email" path="memberEmail">
          <n-input placeholder="Enter Email" v-model:value="form.memberEmail" />
        </n-form-item>
        <n-form-item label="Member AFSC" path="afsc">
          <n-input placeholder="Enter AFSC" v-model:value="form.afsc" />
        </n-form-item>
        <n-form-item label="Flight" path="flight">
          <n-input placeholder="Enter Flight" v-model:value="form.flight" />
        </n-form-item>
        <n-form-item label="Member Rank" path="rank">
          <n-select v-model:value="form.rank" :options="rankOptions" placeholder="Member Rank" />
        </n-form-item>
        <n-form-item label="Losing Unit" path="unit">
          <n-input type="number" placeholder="Enter Unit" v-model:value="form.unit" />
        </n-form-item>
        <n-form-item label="Sponsor Name" path="sponsorName">
          <n-input placeholder="Full Name" v-model:value="form.sponsorName" />
        </n-form-item>
        <n-form-item label="Sponsor Email" path="sponsorEmail">
          <n-input placeholder="Enter Email" v-model:value="form.sponsorEmail" />
        </n-form-item>
        <n-form-item label="RNLTD" path="RNLTD">
          <n-date-picker v-model:value="form.RNLTD" />
        </n-form-item>
        <n-button :focusable="false" @click="submitSponsorship">Submit</n-button>
      </n-form>
    </n-spin>
  </n-card>
</template>

<script setup>
import {
  NCard,
  NButton,
  NForm,
  NFormItem,
  NInput,
  NSelect,
  NDatePicker,
  NSpin,
  useMessage,
} from "naive-ui";
import { ref } from "vue";
import api from "@/api";
import { useSponsorStore } from "@/store/sponsorshipsStore";
import { useKeycloak } from "@baloise/vue-keycloak";
const message = useMessage();
const { decodedToken } = useKeycloak();
const emailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.mil$/;
const formRef = ref(null);
const sponsors = useSponsorStore();
const form = ref({
  memberName: "",
  memberEmail: "",
  rank: null,
  afsc: "",
  sponsorName: "",
  sponsorEmail: "",
  unit: "",
  RNLTD: new Date().getTime(),
});
const processing = ref(false);
const emit = defineEmits(["close"]);
const rankOptions = [
  { label: "AB", value: "AB" },
  { label: "Amn", value: "Amn" },
  { label: "A1C", value: "A1C" },
  { label: "SrA", value: "SrA" },
  { label: "SSgt", value: "SSgt" },
  { label: "TSgt", value: "TSgt" },
  { label: "MSgt", value: "MSgt" },
  { label: "SMSgt", value: "SMSgt" },
  { label: "CMSgt", value: "CMSgt" },
  { label: "2Lt", value: "2Lt" },
  { label: "1Lt", value: "1Lt" },
  { label: "Capt", value: "Capt" },
];
const upper = (val) => {
  form.value.afsc = val.toUpperCase();
};
const rules = {
  memberName: {
    required: true,
  },
  memberEmail: {
    required: true,
    trigger: ["blur", "input"],
    validator: (rule, value) => {
      if (!emailRegex.test(value)) {
        return Error("Please enter a valid .mil email address");
      }
      return true;
    },
  },
  rank: {
    required: true,
  },
  afsc: {
    required: true,
  },
  flight: {
    required: false,
  },
  unit: {
    required: true,
  },
  sponsorName: {
    required: true,
  },
  sponsorEmail: {
    required: true,
    trigger: ["blur", "input"],
    validator: (rule, value) => {
      if (!emailRegex.test(value)) {
        return Error("Please enter a valid .mil email address");
      }
      return true;
    },
  },
  RNLTD: {
    required: true,
  },
};
const submitSponsorship = () => {
  formRef.value.validate(async (err) => {
    if (!err) {
      processing.value = true;
      const username = decodedToken.value.preferred_username;
      const data = {
        createdBy: username,
        memberName: form.value.memberName,
        memberEmail: form.value.memberEmail,
        memberRank: form.value.rank,
        afsc: form.value.afsc.toUpperCase(),
        oldUnit: form.value.unit,
        newUnit: sponsors.unit,
        sponsorName: form.value.sponsorName,
        sponsorEmail: form.value.sponsorEmail,
        rnltd: new Date(form.value.RNLTD).toISOString(),
        createdAt: new Date().toISOString(),
        lastModifiedAt: new Date().toISOString(),
        flight: form.value.flight,
      };

      try {
        const response = await api.postSponsorship(data);
        if (response.status === 200) {
          sponsors.addSponsorship(data);
          sponsors.reconfig();
          message.success("Sponsorship submitted successfully");
          form.value = {
            memberName: "",
            memberEmail: "",
            rank: null,
            afsc: "",
            sponsorName: "",
            sponsorEmail: "",
            flight: "",
            unit: "",
            RNLTD: new Date().getTime(),
          };
          processing.value = false;
          emit("close");
        }
      } catch (error) {
        console.error(error);
        processing.value = false;
        emit("close");
        message.error(error);
      }
    }
  });
};
</script>
