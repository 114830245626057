<template>
  <div style="margin: 0 auto; width: 400px; text-align: left">
    <n-spin :show="loading" setShow="!loading">
      Transfer to:
      <div style="display: flex; gap: 1rem">
        <n-select v-model:value="unitChoice" :options="options" />
        <n-popconfirm
          @positive-click="requestTransfer"
          @negative-click="cancelRequest"
        >
          <template #trigger>
            <n-button
              type="primary"
              ghost
              :disabled="unitChoice === currentUnit"
            >
              Request Transfer
            </n-button>
          </template>
          <template #icon>
            <n-icon color="red" :component="FrontHandFilled" />
          </template>
          <span>Are you sure?</span>
        </n-popconfirm>
      </div>
    </n-spin>
  </div>
</template>
<script setup>
import { ref, watch } from "vue";
import { FrontHandFilled } from "@vicons/material";
import { NSelect, NPopconfirm, NButton, NIcon, NSpin } from "naive-ui";
import { useUnitStore } from "../store/unitStore";
import { useAuthStore } from "../store/authStore";
import { useSponsorStore } from "../store/sponsorshipsStore";
import api from "../api";
const units = useUnitStore();
const auth = useAuthStore();
const sponsor = useSponsorStore();
const unitChoice = ref("");
const currentUnit = ref("");
const options = ref([]);
const loading = ref(false);

function loadData() {
  options.value = units.allUnits
    .filter(({ approved }) => approved)
    .map((unit) => {
      if (unit.unit === sponsor.unit) {
        unitChoice.value = `${unit.unit} - ${unit.type} (current)`;
        currentUnit.value = `${unit.unit} - ${unit.type} (current)`;
      }
      return {
        label: `${unit.unit} - ${unit.type}${
          unit.unit === sponsor.unit ? " (current)" : ""
        }`,
        value: unit.id,
        disabled: unit.unit === sponsor.unit,
      };
    });
}
loadData();

watch(units, loadData);

async function requestTransfer() {
  loading.value = true;
  try {
    await api.transferUnitRequest({
      email: auth.username,
      isSiteAdmin: auth.isSiteAdmin,
      isAdmin: auth.isAdmin,
      oldUnitPermGroup: sponsor.permissionGroupID,
      associatedUnit: unitChoice.value,
      status: "pending",
      approved: false,
    });
    window.location.replace("/");
  } catch (error) {
    console.error(error);
  }
  loading.value = false;
}

function cancelRequest() {
  unitChoice.value = currentUnit.value;
}
</script>
