import { read, utils } from "xlsx";
import { NInput } from "naive-ui";

const clearUp = (str) => {
  const arr = str.split(" ");
  let val = str;
  arr.forEach((word) => {
    if (parseInt(word)) val = word;
  });
  return val;
};

const nameMagic = (str) => {
  const arr = str.replaceAll(",", "").split(" ");
  const first = arr[0];
  const last = arr[1];
  const lowerButCapitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  return `${lowerButCapitalizeFirst(first)} ${lowerButCapitalizeFirst(last)}`;
};

const rankMagic = (str) => {
  const obj = {
    TSG: "TSgt",
    SSG: "SSgt",
    CMS: "CMSgt",
    SRA: "SrA",
    SMS: "SMSgt",
    MSG: "MSgt",
    AMN: "Amn",
    CPT: "Capt",
  };
  return obj[str] || str;
};

const afscMagic = (str) => {
  const officerRegex = /\d\d[A-Z, a-z]/g;
  const enlisted_regex = /(\d[A-Z,a-z]){2}\d[A-Z,a-z]?/g;
  const officer = str.substring(0, 3);
  if (officer.match(officerRegex)) return officer;
  let afsc = str.substring(0, 6);
  while (afsc.split("").length < 6) {
    afsc = afsc + "X";
  }

  const data = afsc.split("").map((char, i) => {
    if (i === 3) {
      return "X";
    } else if (i === 5) {
      if (char === "X") return "";
    }
    return char;
  });
  return data.join("");
};

// function that turns csv text into javascript array of objects
export function csvJSON(file) {
  let Err = null;
  const wb = read(file);
  const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
  ["FULL_NAME", "GRADE", "DAFSC", "LOSING_PAS_CLEARTEXT", "RNLTD"].forEach(
    (key) => {
      if (!data[0][key]) {
        Err = `Invalid GAINS Roster`;
      }
    }
  );
  if (Err) return { Err };
  const theRows = data.map(
    ({ FULL_NAME, GRADE, DAFSC, LOSING_PAS_CLEARTEXT, RNLTD }) => {
      return {
        "FULL NAME": nameMagic(FULL_NAME),
        GRADE: rankMagic(GRADE),
        DAFSC: afscMagic(DAFSC.replaceAll("-", "")),
        "LOSING UNIT": clearUp(LOSING_PAS_CLEARTEXT),
        RNLTD,
      };
    }
  );
  const theHeaders = Object.keys(theRows[0]).map((key) => {
    return {
      title: key,
      key,
      render(row) {
        return <NInput v-model:value={row[key]} />;
      },
    };
  });

  return { theHeaders, theRows, Err };
}

// export const getHeaders = (file) => {
//   // const lines = file.split("\n");
//   // const headers = lines[0].split(",");
//   // const headers = read(file).Sheets[read(file).SheetNames[0]];
//   const wb = read(file);
//   const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
//   console.log(data);
//   return Object.keys(data[0]);
// };

// export const getRows = (csv) => {
//   const lines = csv.split("\n");
//   let rows = [];
//   lines.forEach((row, i) => {
//     if (i !== 0) rows.push(row.split(","));
//   });
//   return rows;
// };
